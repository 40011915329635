.header {
    display: flex;
    flex-direction: row;
    gap: 8px;
    @media (max-width: 768px) {
        margin-top: 16px;
    }
}

.goBackButton {
    min-width: 24px;
    min-height: 24px;
    position: relative;
}

.heading {
    margin: 0;
    margin-right: 20;
    white-space: 'nowrap';
    font-size: 24px;

    &_leftPosition {
        width: 100%;
    }

    @media (max-width: 1000px) {
        font-size: 22px;
    }
}

.selectDescription {
    margin: 0;
    margin-bottom: 8px;
    color: #111;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;

}

.singleValueBlock {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;

    &__icon {
        flex-shrink: 0;
        width: 14px;
        height: 14px;
        transition: transform 0.3s ease;

        svg {
            width: 14px;
            height: 14px;
        }

        &_focused {
            transform: rotate(180deg);
        }
    }

    &__title {
        font-size: 11px;
        font-weight: 400;
        letter-spacing: 0.11px;
        color: #111;
    }
}