.documents-list-wrapper {
  display: grid;
  grid-column-gap: 10px;
}

// grid-columns
.documents-columns-1 {
  grid-template-columns: repeat(1, 1fr);
}

.documents-columns-2 {
  grid-template-columns: repeat(2, 1fr);
}

.documents-columns-3 {
  grid-template-columns: repeat(3, 1fr);
}