@mixin fenceButton($bg-color, $color, $width) {
  border: 1px solid black;
  font-size: 11px;
  text-decoration: none;
  color: $color;
  background-color: $bg-color;
  width: $width;
  height: 27px;
  border-radius: 4px;
}

@mixin fenceButtonsBlock($padding) {
  height: 52px;
  padding: $padding;
  display: flex;
  justify-content: space-between;
  position: relative;
  &:before {
    content: '';
    display: block;
    width: 107%;
    height: 1px;
    border-bottom: 1px solid rgb(236, 236, 236);
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@mixin fenceMobile {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 91vh;
}