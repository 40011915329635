.radio-input {
  width: 100%;

  .disabled {
    opacity: 0.5;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 20px;
  }

  &__label {
    display: flex;
    justify-content: space-between;
    // max-width: 300px;

    span {
      font-weight: bold;
      font-size: 14px;
    }
  }

  .radio[type="radio"]:checked,
  .radio[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  .radio[type="radio"]:checked + label,
  .radio[type="radio"]:not(:checked) + label {
    position: relative;
    cursor: pointer;
    line-height: 20px;
    display: inline-flex;
    color: #666;
    width: 100%;
    align-items: center;
    padding: 10px 10px 0 50px;
  }

  .radio[type="radio"]:checked + label:before,
  .radio[type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 13px;
    top: 10px;
    width: 17px;
    height: 17px;
    border: 2px solid #f26f22;
    opacity: 0.4;
    border-radius: 100%;
    background: #fff;
    box-sizing: content-box;
  }

  .radio[type="radio"]:checked + label:after,
  .radio[type="radio"]:not(:checked) + label:after {
    content: "";
    width: 9px;
    height: 9px;
    background: #f26f22;
    position: absolute;
    top: 16px;
    left: 19px;
    border-radius: 100%;
    transition: ease 0.5s;
    transform: scale(1);
  }

  .radio[type="radio"]:not(:checked) + label:after {
    transform: scale(0);
  }

  .radio[type="radio"]:not(:checked) + label:before {
    border: 2px solid #222;
  }
}
