.fenceName {
  color: #111111;
  font-size: 11px;
  flex: 1 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fenceLinks {
  flex: 1;
  text-align: right;
  align-items: center;
  height: 16px;
}

.fenceTagName {
  flex: 1;
  cursor: default;
  color: #999;
  text-align: right;
  font-size: 11px;
}

.fenceGpsIcon, .FenceTuneIcon {
  width: 16px !important;
  height: 16px;
}

.FenceTuneIcon {
  margin-left: 5px;
}

.fenceGpsIcon {
  margin-left: 8px;
}