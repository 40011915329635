.modal__content {
  position: relative;
  overflow-y: scroll;
  max-height: 90vh;
  padding: 20px;
  border-radius: 12px;
  background-color: white;
  width: 60vw;

  @media (max-width: 1060px) {
    max-height: 90vh;
    width: 80vw;
  }
}

.modal__content::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn ease 1s;
}
