.indicatorDot {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 3px;
    border: 1px solid black;
}

.red {
    background-color: red;
}

.green {
    background-color: green;
}

.yellow {
    background-color: yellow;
}

.pink {
    background-color: pink;
}

.markerInfoContainer {
    position: relative;

    .markerImage {
        width: 40px;
        height: 40px;
        object-fit: fill;
    }
}

.messageBlock {
    color: red;
    text-overflow: ellipsis;
    padding: 0 5px;
    white-space: nowrap;
    font-size: 11px;
    line-height: 14px;
    display: flex;
    align-items: center;
    column-gap: 3px;

    &.onTheMarker {
        position: absolute;
        font-size: 13px;
        top: 50%;
        transform: translateY(-50%);
    }
}