.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 103;

  padding: 10px;
  overflow-y: auto;
  transition: .3s ease-in-out;

  &.open {
    background-color: rgba(0, 0, 0, 0.6);

    .modal-window {
      opacity: 1;
    }
  }

  .modal-window {
    opacity: 0;
    transition: .3s ease-in-out;
    margin: 30px auto;
    background-color: #FFF;
    min-height: 100px;
    width: 500px;
    max-width: calc(100vw - 20px);
    border-radius: .3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > div {
      padding: 15px;
    }

    .modal-header {
      min-height: 60px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      align-items: center;

      span {
        svg {
          cursor: pointer;
        }
      }
    }

    .modal-title {
      font-weight: bold;
      font-size: larger;
    }

    .modal-footer {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: flex-end;
      align-items: center;

      & > *:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
