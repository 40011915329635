.image {
  width: 100px;
  margin-right: 8px;
  height: 100px;
  cursor: pointer;
  box-shadow: 5px 5px 16px -2px rgba(194,194,194,1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.colorPicker {
  margin-left: 25px;
  margin-bottom: 20px;
}

.titleInput {
  /*inputCSS*/
  border: 1px solid #2f76c3;
  padding: 2px;
  outline: none;
  /*End inputCSS */
  width: 100%;
}

.selectUnits {
  /*selectCSS*/
  padding: 2px;
  outline: none;
  /*End selectCSS */
  width: 100%;
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}