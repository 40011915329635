@import "../../../../assets/styles/variables";

.DeviceTags {
  .DeviceTagsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    background-color: $white;

    .DeviceTagsHeaderTitle {
      display: flex;
      gap: 4px;

      span {
        color: #111;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .DeviceTagsHeaderBtns {
      display: flex;
      gap: 8px;
    }
  }
}

.tagsList {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 12px;
  background: $main_white;
}
