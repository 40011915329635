.StandardTileBody {
  overflow: auto;
  padding: 3px 8px;
  border: 1px solid #e5e5e5;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.StandardTileTitle {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-color: #6a6a6a;
  color: #fff;
  padding: 3px 8px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}