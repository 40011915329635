@import "../../../../assets/styles/variables.scss";

.report-scheduled-wraper {
  padding: 20px;
  flex: 1;
  background-color: $main_white;
}

.report-table {
  flex: 1;
  font-size: 12px;
  line-height: 14px;
  background-color: $main_white;
}

.ellipsis {
  display: block;
  width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.label-cell {
  text-decoration: none;

  span.btn-link{
    color: $main_black;
  }
}

.remove-cell {
  margin-right: 12px;
  text-align: right;
}

.report-scheduled {
  width: 100%;
  min-width: 800px;
  font-size: 12px;
  line-height: 12px;
  .report-scheduled__row {
    padding: 10px;
    display: grid;
    grid-template-columns: 0.5fr 2fr 1fr 1fr 0.5fr 0.5fr;
  }

  .report-scheduled__row.report-scheduled__row__header {
    background-color: $bg_input;
  }

  .report-scheduled__col {
    display: flex;
    align-items: center;
  }

  .report-scheduled__delete {
    cursor: pointer;
    fill: grey;

    &:hover {
      fill: black;
    }
  }

  .details-cell {
    cursor: pointer;
  }
}
