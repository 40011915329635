.userCard {
  min-width: 270px;
  height: 150px;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.selectedCard {
  border: 2px solid #100f0f;
}

.cardPrimary {
  background-color: #c25928;
}

.cardBackup {
  background-color: #3b3b3b;
}

.cardBox {
  position: relative;
  min-height: 150px;
  padding: 16px 16px 25px;
  color: #fff;
}

.cardName {
  font-size: 12px;
  padding-left: 5px;
}

.cardNumber {
  position: absolute;
  font-size: 14px;
  top: 50%;
  left: 50%;
  letter-spacing: 2px;
  font-weight: 500;
  transform: translateY(-50%) translateX(-50%);
}

.cardExpires {
  position: absolute;
  opacity: 0.2;
  bottom: 20px;
  left: 15px;
  z-index: 2;
}

.cardActiveBottom {
  position: absolute;
  bottom: 20px;
  left: 15px;
  z-index: 2;
  padding-left: 9px;
  text-transform: uppercase;
}

.waveIcon {
  position: absolute;
  bottom: 0;
  left: 0;
}