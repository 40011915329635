.modal__confirm {
  text-align: center;

  &-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    @media (max-width: 1420px) {
      justify-content: center;
    }

  }
}