@import "../fences.mixins";
@import "../../../assets/styles/variables";


.FilterEdit {
  .ButtonsBlock {
    @include fenceButtonsBlock(12px 0 0 16px);

    .WhiteButton {
      @include fenceButton(white,black,94px);
    }
    .BlackButton {
      @include fenceButton(black, white, 94px);
    }
  }
}

.TittleBox {
  height: 38px;
  border-bottom: 1px solid #ECECEC;
  display: flex;
  align-items:center;
  justify-content: space-between;
  padding: 14px 12px 7px;

  span {
    font-size: 11px;
    font-weight: 500;
  }

  &-Icon {
    width: 16px;
  }
}

.SvgElement {
  cursor: pointer;

  &-trash {
    cursor: pointer;
    fill: $main_red;
    width: 16px;
  }
}

.EditFilterBox {
  padding: 20px 0 40px 16px;
  border-bottom: 1px solid #ECECEC;
  min-height: 525px;
}

.SelectBox {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  span {
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    margin-bottom: 8px;
  }
}

.EditForm {
  display: flex;
  flex-direction: column;
}

.LightTitle {
  font-size: 11px;
  font-weight: normal;
  margin-bottom: 8px;
}

.LabelInput {
  border: none;
  background-color: #F7F7F7;
  border-radius: 2px;
  font-size: 12px;
  padding: 12px;
  margin-bottom: 20px;
  color: black;
  opacity: 0.5;
}

.DataPickerBox {
  height: 44px;
  border: none;
  background-color: #F7F7F7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;

  svg {
    height: 16px;
    width: 16px;
  }

  span {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    opacity: 0.5;
  }

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .EditFilterBox {
    height: 83vh;
  }
}
