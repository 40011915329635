.credits {
  display: none;
  justify-content: space-between;
  align-items: center;

  & span {
    font-weight: bold;
  }
}

.block {
  display: flex;
  flex-direction: column;
}

.block:first-child {
  margin-right: 50px;
}

.name-card {
  margin-bottom: 10px;
  color: #3b3b3b;
  font-size: 12px;
}

.cards-block {
  display: flex;
  margin-top: 30px;
  color: white;
  font-size: 10px;
  height: 500px;
  justify-content: center;
  margin-bottom: 20px;

  .cards-container {
    position: relative;
    overflow-x: scroll;
    min-width: 100px;
  }

  .cards {
    display: flex;
    margin-bottom: 20px;
  }

  .tracker-btn {
    position: sticky;
    left: 0;
    background-color: darkgrey;
    width: 100%;
    padding: 10px;
    color: white;
    font-size: 14px;
    border-radius: 10px;

    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }
  }

}

.cards-container::-webkit-scrollbar {
  display: none;
}
