.MainContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    color: var(--colorDark);

    .MainLogo {
        background: var(--headerBackground);
        width: 100%;
        padding: 15px 50px;
        margin-top: 20px;

        .MainLogoImg {
            display: block;
            margin: 0 auto;
            width: 100%;
            height: 100%;
            max-height: 130px;
            max-width: 240px;
        }
    }

    .MainNavLink {
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 52px;
    }

    .MainLinks {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 10px 0;
        padding: 0 50px;

        .MainNavLink {
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 52px;
        }
    }

    .MainUserInfo {
        padding: 25px 50px 0;
        border-top: 1px solid #CFCFCF;
        width: 100%;
        text-align: center;
        color: #111111;
        font-weight: normal;
        h3 {
            font-weight: bold;
            font-size: 20px;
            line-height: 20px;
            margin: 0 0 5px;
        }
        p {
            font-size: 12px;
            line-height: 12px;
            color: #878889;
            margin: 0 0 25px;
        }

        span {
            font-size: 14px;
            line-height: 14px;
        }

    }

}
