.tableLink {
  color: #fff;
}

.tableItem {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: 'ellipsis';
  max-width: 170px;

  .VisibleDevicesTableHead {
    display: flex;
    justify-content: space-between;

    .button {
      margin: 10px 0;
    }
  }
}
