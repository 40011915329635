@font-face {
  font-family: 'Roboto';
  src: url('../font/Roboto-Thin.ttf') format('truetype'),
  url('../font/Roboto-Thin.woff') format('woff'),
  url('../font/Roboto-Thin.woff2') format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../font/Roboto-ThinItalic.ttf') format('truetype'),
  url('../font/Roboto-ThinItalic.woff') format('woff'),
  url('../font/Roboto-ThinItalic.woff2') format('woff2');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../font/Roboto-Light.ttf') format('truetype'),
  url('../font/Roboto-Light.woff') format('woff'),
  url('../font/Roboto-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../font/Roboto-LightItalic.ttf') format('truetype'),
  url('../font/Roboto-LightItalic.woff') format('woff'),
  url('../font/Roboto-LightItalic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../font/Roboto-Regular.ttf') format('truetype'),
  url('../font/Roboto-Regular.woff') format('woff'),
  url('../font/Roboto-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../font/Roboto-Italic.ttf') format('truetype'),
  url('../font/Roboto-Italic.woff') format('woff'),
  url('../font/Roboto-Italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../font/Roboto-Medium.ttf') format('truetype'),
  url('../font/Roboto-Medium.woff') format('woff'),
  url('../font/Roboto-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../font/Roboto-MediumItalic.ttf') format('truetype'),
  url('../font/Roboto-MediumItalic.woff') format('woff'),
  url('../font/Roboto-MediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../font/Roboto-Bold.ttf') format('truetype'),
  url('../font/Roboto-Bold.woff') format('woff'),
  url('../font/Roboto-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../font/Roboto-BoldItalic.ttf') format('truetype'),
  url('../font/Roboto-BoldItalic.woff') format('woff'),
  url('../font/Roboto-BoldItalic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../font/Roboto-Black.ttf') format('truetype'),
  url('../font/Roboto-Black.woff') format('woff'),
  url('../font/Roboto-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../font/Roboto-BlackItalic.ttf') format('truetype'),
  url('../font/Roboto-BlackItalic.woff') format('woff'),
  url('../font/Roboto-BlackItalic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
}


@font-face {
  font-family: 'Ayar Juno';
  src: url('../font/AyarJuno.woff2') format('woff2'),
      url('../font/AyarJuno.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
