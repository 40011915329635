@import "../../../assets/styles/variables.scss";

.desktop-layout
.date-choosers {
    background-color: $bg_gray;
}

.desktop-layout
.report-selectable {
    div.DayPicker-wrapper {
        width: fit-content;
        margin: 0 auto;
    }
    div.DayPicker {
        display: flex;
        justify-content: center;
        background-color: $bg_gray;
      }

      div.DayPicker-Caption {
        text-align: center;
        font-size: 11px;
        line-height: 12px;
      }

      div.DayPicker-NavBar {
        margin-bottom: 0;
        .DayPicker-NavButton--prev {
          left: 20px;
        }
        .DayPicker-NavButton--prev,
        .DayPicker-NavButton--next {
            top: 1em;
        }
      }

      div.DayPicker-Month {
        border-collapse: separate;
        border-spacing: 0 5px;
        column-gap: normal;
      }

      div.DayPicker-Day {
        padding: 8px 0px;
        font-size: 12px;
        line-height: 12px;
        font-weight: 400;
      }

      div.DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        background-color: transparent;
        box-shadow: 0 0 0 1px black;
        border-radius: 5px;
      }


      div.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: $main_orange;
        color: $main_black;
        border-radius: 4px;
        font-weight: bold;
        &:hover {
          background-color: $main_orange;
        }
        & + .DayPicker-Day--selected {
          box-shadow: -5px 0 0 0 $main_orange;
        }
      }
}

.time-options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  font-size: 12px;

  $child-element-gap: 10px;
  > * {
    margin-right: $child-element-gap;
  }
  margin-right: -#{$child-element-gap};
}

.time-options-container__header {
  color: $inactive;
}

.time-options {
  width: 150px;
  display: flex;
  justify-content: space-between;
}

.time-options__mas-input {
  background-color: $deviders;
  padding: 6px;
  border: none;
  outline: none;
}