.filters__modal__wrapper {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 103;
  padding: 10px;
  overflow-y: auto;
  transition: 0.3s ease-in-out;

  backdrop-filter: brightness(0.3);
}

.filters__modal {
  width: 40%;
  padding: 10px 20px;

  max-height: 90vh;

  display: flex;
  flex-direction: column;

  background-color: #fff;

  &__header {
    align-self: flex-start;

    width: 100%;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: large;
    font-weight: 600;
  }

  &__content {
    width: 100%;
    max-height: calc(90vh - 150px);
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    gap: 10px;

    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 2px;
    }
  }

  &__footer {
    width: 100%;
    height: 50px;

    align-self: flex-end;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.report-filter-labels-header {
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
}

.report-filter-icon {
  margin-right: 20px;
  width: 20px;
}
