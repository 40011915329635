.activation {

  &__title {
    font-weight: 800;
    color: orange;
    width: 90%;
  }

  &__devices {
    margin-bottom: 50px;

    &-header {
      display: flex;
      padding: 16px 30px 16px 30px;

      div {
        flex-basis: calc(100% / 3);
        text-transform: uppercase;
        color: rgba(34, 34, 34, 0.5);
        font-size: 16px;
        font-weight: 700;
      }
    }

    &-content {
      position: relative;
      min-height: 20vh;
    }

    &-line {
      display: flex;
      align-items: center;
      padding: 16px 30px 16px 30px;
      color: black;
      margin-top: 10px;
      font-size: 14px;

      div {
        flex-basis: calc(100% / 3);
      }

      div:first-child {
        font-weight: bold;
      }

      button {
        position: relative;
        display: inline-block;
        color: orange;
        font-weight: 600;
        padding: 0;
      }

      button:after {
        content: "";
        position: absolute;
        width: 0%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: #0087ca;
        transition: width 0.25s ease-out;
      }

      button:hover:after {
        width: 100%;
      }
    }

    &-line:nth-child(2n-1) {
      background-color: #f7f7f7;
      border-radius: 5px;
    }
  }
}

.empty-list {
  text-align: center;
  padding-top: 10vh;
  font-weight: 700;
}