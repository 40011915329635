.deviceGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
    gap: 12px;
    margin-bottom: 72px;
    margin-top: 10px;
}

.deviceList {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 72px;
    margin-top: 10px;
}

.showFullCardWidthOnSmallScreensOn1505pxScreen {
    @media (max-width: 1505px) {
      width: 100%;
    }
  }