@import "./src/assets/styles/variables";

.tasks-table {
  margin: 16px 0 20px;
  border: 1px solid $dividers;

  .mobile-layout & {
    margin: 0;
  }

  &-wrapper {
    .mobile-layout &{
      margin: 32px 0 0;
    }
  }

  &-head {
    background: $bg_input;
    border: 1px solid $dividers;

    &-row {
      &-itm {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: $main_black;

        .mobile-layout & {
          white-space: nowrap;
        }
      }
    }
  }

  &-body {
    background: $main_white;

    &-row {
      &-itm {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 12px;
        color: $main_black;
        padding-top: 12px;
        padding-bottom: 12px;
        &__delete_button {
          cursor: pointer;
        }
      }
    }

    .taskName {
      &-small {
        min-width: 92px;
      }

      word-break: break-word;
      min-width: 190px;

      .mobile-layout & {
        min-width: 92px;
      }
    }
  }

  &__pagination {
    font-size: 12px;
  }
}