.mobile-layout {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  overflow: hidden;
  .mobile-settings {
    display: grid;
    flex: 1;
    align-content: center;

  }

  .main-content {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
    margin-bottom: -88px;

    .google-map-block {
      position: relative;
      flex-grow: 1;
      transition: all 0.5s;

      &.closed {
        z-index: -1;
        flex-grow: 0;
        width: 100%;
        height: 50%;
        opacity: 0;
        position: absolute;
      }
      &.opened {
        z-index: 2;
        width: 100%;
        height: 100%;
      }
    }
  }
  .modal-menu-overlay-open {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    background: rgba(0, 0, 0, 0.5);
  }
  .modal-menu-overlay-close {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    transition: 0.3s;
    div {
      display: none;
    }

  }
  .modal-menu-styles-open {
    position: absolute;
    top: 0;
    left: 0;
    // fix ios dock overflow
    // 100vh make the side menu to overflow it
    height: 100%; 
    z-index: 4;
    width: 80%;
    background-color: white;
    transition: 0.3s;
  }
  .modal-menu-styles-close {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
    width: 0;
    transition: 0.3s;
    color: white;
    div {
      display: none;
    }
  }
}
