.modalContinueLive{
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    width: 350px;
  
    & > h4{
      text-align: center;
    }
  
    &__buttons{
      display: flex;
      justify-content: space-around;
    }
  
    & .selector{
      margin: 15px 0;
      display: flex;
      justify-content: center;
      align-items: center;
  
      & label{
        margin: 0;
      }
    }
  
    & select{
      margin-left: 10px;
      padding: 5px;
    }
  
    & .actions {
      display: flex;
      gap: 20px;
    }
  
    & button{
      background-color: #FF7C02;
      color: #FFFF;
      fill: #FFFF;
      transition: .3s ease;
      width: 100px;
      margin: 10px auto 0 auto;
      font-size: 18px;
      cursor: pointer;
      border-radius: 3px;
    }
  
    & .goToBilling {
      width: 100%;
    }
  }
  
  .tariffsList{
    list-style: none;
    padding: 0;
    & > li{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 0;
      & > p{
        margin: 0;
      }
      & input[type="radio"]{
        width: 18px;
        height: 18px;
        cursor: pointer;
        accent-color: green;
        margin-right: 10px;
      }
      & > div{
        display: flex;
        align-items: center;
      }
    }
  }
  