@import "../../assets/styles/variables";

.BookmarksBarWrapper {
  display: flex;
  align-items: center;

  .BookmarksBar {
    display: flex;
    align-items: center;
    padding: 12px;
    gap: 12px;
    overflow: hidden;
    width: calc(100% - 100px);

    .Bookmark {
      border: 1px solid #7b7b7b;
      padding: 8px 16px;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      cursor: pointer;
      height: 36px;
      color: #7b7b7b;
      width: 160px;
      flex-shrink: 0;

      &:hover {
        border: 1px solid $main_black;
        color: $main_black;
      }

      p {
        text-align: center;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 12px;
        font-weight: 400;
        margin: 0;
      }

      .BookmarkDelete {
        height: 100%;
        width: 100%;
        transition: transform 0.2s ease-in-out;

        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}

.ScrollButton {
  background-color: $white;
  height: 36px;
  width: 36px;
  border: 1px solid $dividers;
  border-radius: 4px;
  padding: 8px;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 16px;
}

.AddBookmarkModal {
  background-color: $white;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  min-height: 350px;
  justify-content: space-between;
  border-radius: 10px;
  max-width: 500px;

  .AddBookmarkTitle {
    padding: 20px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }

  .AddBookmarkInputs {
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    padding-left: 20px;
    gap: 16px;
  }

  .AddBookmarkInputContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;

    input {
      padding: 8px 12px;
      min-width: 350px;
      border-radius: 2px;
      border: none;
      background-color: #f7f7f7;
      color: $main_black;
      font-size: 12px;
      font-weight: 400;

      &::placeholder {
        color: $inactive2;
      }

      &:focus {
        outline: none;
      }

      @media (max-width: 568px) {
        min-width: 250px;
      }
    }

    label {
      font-size: 11px;
      font-weight: 300;
      color: $main_black;
    }
  }

  .ToggleContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;

    span {
      font-size: 11px;
      font-weight: 300;
      color: $main_black;
    }

    .ToggleInputsContainer {
      display: flex;
      gap: 16px;

      .ToggleInputContainer {
        display: flex;
        gap: 8px;

        .ToggleInput {
          appearance: auto;
          -webkit-appearance: radio;
          -moz-appearance: radio;
          accent-color: rgb(255, 98, 2);

          &:hover {
            accent-color: rgb(255, 98, 2);
          }
        }

        label {
          font-size: 11px;
          font-weight: 400;
          color: $main_black;
          margin: 0;
        }
      }
    }
  }

  .AddBookmarkIconsContainer {
    label {
      font-size: 11px;
      font-weight: 300;
      color: $main_black;
    }

    .AddBookmarkIcons {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      .AddBookmarkIcon {
        cursor: pointer;
        height: 36px;
        width: 36px;
        border: 1px solid #F4F4F4;
        padding: 8px;
        border-radius: 2px;

        &:hover {
          border: 1px solid $main_black;
          color: $main_black;
        }
      }
    }
  }

  .AddBookmarkErrors {
    display: flex;
    flex-direction: column;
    margin: 30px auto 0;
    padding: 0 10px;
    max-width: 400px;

    .AddBookmarkError {
      font-size: 14px;
      text-align: center;
      color: $denied;
      background-color: #fff3f3;
      border: 1px solid #f2cccc;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 8px;
      position: relative;

      display: flex;
      align-items: center;

      &::before {
        content: '';
        width: 10px;
        height: 10px;
        position: absolute;
        border-radius: 5px;
        top: 0;
        right: 0;
      }
    }
  }

  .AddBookmarkBtns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 7px;
    padding-top: 40px;
    padding-bottom: 20px;
    position: relative;

    button {
      width: 64px;
      height: 28px;
      font-size: 12px;
      font-weight: 600;
      padding: 8px 12px;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .AddBtn {
      color: $white;
      background-color: $main_orange;
    }

    .CancelBtn {
      color: #7b7b7b;
      background-color: $white;
      border: 1px solid #f7f7f7;
    }

    .HorizontalLine {
      position: absolute;
      padding: 0 20px 0;
      width: 100%;
      left: -20px;
      top: 0;
      color: $deviders;
    }
  }
}

.searchPart {
  height: 60px;
  display: flex;
  align-items: center;
  padding-right: 12px;
  padding-left: 12px;
  border-left: 1px solid #ECECEC;
}

.searchButton {
  width: 44px;
  height: 44px;
  background-color: #ECECEC;
  border-radius: 4px;
  padding: 11px;

  &__searchIcon {
    width: 22px;
    height: 22px;
    fill: #111111;
    transition: transform 0.3s ease;
  }

  &:hover &__searchIcon {
    transform: scale(1.1);
  }
}

.BookmarksSearchPart {
  padding: 8px 12px;
  width: 100%;
}