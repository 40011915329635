.billing-tables {
    font-family: 'Roboto', sans-serif;
    border-collapse: collapse;
    width: 100%;
    min-width: 850px;
    margin-bottom: 20px;
    font-size: 14px;

  .edit-row {display: flex;}

  & th, tr {
    height: 40px;
  }

  & td, th {
    position: relative;
    border: 1px solid #ddd;
    padding: 5px 0 5px 10px;

    & input {
      width: 100px;
    }
  }

  & th:nth-child(-n+2) {
    width: 150px;
  }

  & tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  & tr:hover {
    background-color: #ddd;
  }

  & td {
    input {
      font-size: 1rem;
      padding: 0;
      margin: 0;
      border: 0;
      background: transparent;
    }

    & input:focus-visible {
      border-radius: 5px;
      font-size: 1rem;
      padding: 0 5px 0 0;
      outline: none;
      margin: 0;
      border: none;
      color: white;
      background: #3b3b3b;
    }

  }

  & th, tfoot td {
    padding-top: 5px;
    padding-bottom: 10px;
    background-color: #4a4a4a;
    color: white;
    text-align: left;
  }

  .sort-icon {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);

    svg {
      width: 16px;
      height: 16px;
    }
  }
}


.activate-btn {
  color: white;
}

.deactivate-btn {
  color: white;
  background: gray;
  border-radius: 5px;
  width: 100%;
  height: 40px;
}

.input {
  border-radius: 5px;
}

.row-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;

  ul {
    // list-style: none;
    padding: 10px;
    margin-bottom: 0;
  }

  & span {
    // margin-right: 20px;
  }

  svg {
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin-left: 4px;
    fill: white;
  }
}

.row-active {
    display: flex;
    align-items: center;
    justify-content: center;
    background: mediumseagreen;
    border-radius: 5px;
    min-width: 200px;
    min-height: 40px;
}

.row-edit {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.pagination {
  position: sticky;
    left: 50%;
    right: 50%;
    transform: translateX(-50%);

  margin-bottom: 50px;
  width: max-content;

  .MuiPaginationItem-page.Mui-selected {
    background-color: orange;
  }
  .MuiPaginationItem-page.Mui-selected:hover {
    background-color: orange;
  }

  .page-size { background: #4a4a4a;}

  & div {
    padding: 10px;
    border-radius: 5px;
    margin-right: 20px;
    color: white;
  }

  & button {
    background: #e4e4e4;
    border-radius: 5px;
    padding: 10px;
    max-height: 30px;
    min-width: 30px;
    margin-right: 10px;
  }
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.ps__rail-x, .ps__rail-y {
  z-index: 1;
}