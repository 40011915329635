.buttonStyles {
  color: #fff;
  font-weight: 600;
  padding: 4px 8px;
  white-space: nowrap;
  cursor: pointer;
  background-color: rgb(47, 118, 195);
  border-radius: 6px;
  &:hover {
    text-decoration: underline;
  }
}

.labelTdStyles {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
}

.headerBlock {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  .title {
    margin-bottom: 0;
  }
}
