@import "../../../../assets/styles/variables";

.DeviceEngineHistory {
  display: flex;
  flex-direction: column;
  background-color: $white;
  padding: 8px 12px;
  border-radius: 4px;

  .DeviceEngineBar {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .DeviceEngineHeader {
      display: flex;
      gap: 4px;
      color: #111;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .DeviceEngineBody {
    border-top: 1px solid #cecece;
    padding: 12px 8px 0px 8px;
  }
}
