@import "../../assets/styles/variables.scss";

.mouse-tooltip {
    background-color: $tooltip_background;
    color: $main_white;

    padding: 5px 10px;
    border-radius: 5px;

    font-size: 12px;
}