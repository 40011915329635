.activation-form {

  &__title {
    margin-bottom: 20px;
  }

  &__inputs {
    display: flex;
    gap: 50px;

    &-input {
      width: 100%;
    }

    label {
      position: relative;
      margin-bottom: 0;
    }
  }
}