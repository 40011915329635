@import "../../assets/styles/variables";

$border-radius-units: 4;
$active-tab_z-index: 1;

$tab-height-units: 32;
$tab-horizon-border-units: $tab-height-units * 1/2;

$theme-light-text-color: #000;
$theme-light-tab-bg-color: $snow;
$theme-light-tab-active-bg-color: #fff;

$theme-dark-text-color: rgba(#FFF, 0.8);
$theme-dark-active-text-color: #FFF;
$theme-dark-tab-bg-color: $main_black;
$theme-dark-tab-active-bg-color: #151515;
