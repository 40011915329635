.plans {
  position: relative;
  border-radius: 10px;
  padding: 15px;
  background: #4d4646;
  color: white;
  margin: 30px 0;
  font-size: 14px;
  width: 100vw;
  min-width: 300px;
  max-width: 450px;
  margin-right: 10px;
  opacity: 0.8;

  &:last-child {
    margin-right: 0;
  }

  &__header {
    margin-bottom: 15px;
    font-weight: bold;
  }

  &__line {
    display: flex;
    justify-content: space-between;
    padding: 2px;
  }

  &__line:nth-child(5) {
    margin-bottom: 20px;
  }

  &__btn {
    position: absolute;
    padding: 10px;
    margin-top: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    background: orangered;
    color: white;
  }

  &__btn:hover {
    cursor: pointer;
    transition: ease-in-out 0.4s;
    background: rgba(0, 0, 0, 0.8);
  }
}
