.modalContent {
  position: relative;
  overflow-y: scroll;
  max-height: 90vh;
  padding: 20px;
  border-radius: 12px;
  background-color: white;
  width: 60vw;

  @media (max-width: 1060px) {
    max-height: 90vh;
    width: 80vw;
  }
}

.modalContent::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.modalHeader {
  &Row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    button {
      height: 40px;
      width: 40px;
      background: #4d4646;
      border-radius: 10px;
      color: white;

      &:hover {
        background: grey;
        transition: ease-in 0.3s;
      }
    }
  }

  h2 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
  }
}

.modalError {
  margin-top: 40px;

  h3 {
    text-align: center;
    color: red;
  }

  p {
    text-align: justify;
  }
}

.styledInput {
    box-sizing: border-box;
    font-size: 18px;
    border: none;
    background: transparent;
    color: black;
    border-bottom: 1px solid grey;
    outline: none;
    padding: 8px 3px;
    font-family: inherit;
}

.labelTitle {
  font-size: 18px;
  margin: 20px 0 10px;
}

.flex {
  display: flex;
  gap: 20px;
}

.buttons_block {
  display: flex;
  justify-content: flex-end;
  margin-right: 5px;
  gap: 15px;
}

.channelLabel {
  margin-left: 10px;
}

.container {
  // display: block;
  position: relative;
  padding-left: 35px;
  padding-right: 20px;
  // margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

.container:hover input ~ .checkmark {
  background-color: #ccc;
}

.container input:checked ~ .checkmark {
  background-color: #2196F3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  box-sizing: content-box;
}

.seconds {
  width: 100px;
}

.inputBlock {
  display: flex;
  flex-direction: column;
}