.settings__no-content {
  display: flex;
  justify-content: center;
}

.settings__speed {
  display: flex;
  align-items: center;
  flex-direction: column;

  padding: 10px;
  background-color: #333;
  border-radius: 3px;

  .settings__speed-title {
    display: flex;
    justify-content: center;
    flex: 1;

    color: #fff;
    margin-bottom: 10px;
    font-size: 18px;
  }

  .settings__speed-knob {
    margin-bottom: -33px;
  }

  .settings__speed-content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;

    color: #ffec03;
    width: 200px;
    z-index: 2;

    .settings__speed-content__mph,
    .settings__speed-content__kph,
    .settings__speed-content__knots {
      display: flex;
      align-items: center;
      flex-direction: column;
      text-align: center;
      line-height: 15px;
    }

    .settings__speed-content__slash {
      display: flex;
      align-items: center;
      text-align: center;
      line-height: 15px;
      font-size: 30px;
    }
  }
}
