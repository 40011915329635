@import "../../../assets/styles/variables";

.DayPicker-NavBar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: getVw(10);

  font-size: getVw(11);
  line-height: getVw(12);

  color: #FFF;

  .DayPicker-CustomNavButton {
    cursor: pointer;
    opacity: 0.85;
    transition: .3s ease-in-out;

    svg {
      width: getVw(16);
      fill: #FFF;
      margin: 0 getVw(5);

      &.turn-left {
        transform: matrix(-1, 0, 0, 1, 0, 0);
      }
    }

    &.disabled {
      cursor: default;
      opacity: 0.2;
    }

    &:not(.disabled):hover {
      opacity: 1;
      transform: scale(1.2);
    }
  }
}

$day-item-height-units: 24;
$current-day-border-width-units: 1;

.DayPicker-wrapper {
  outline: none;
}

.Selectable {
  margin: getVw(32) auto 0;
  width: 100%;

  display: block;
  text-align: center;

  .DayPicker-wrapper {
    display: inline-block;
    outline: none;
  }

  .DayPicker-wrapper:focus {
    //outline: 1px solid $main_orange;
    outline: none;
  }

  .DayPicker-Months {
    flex-wrap: nowrap;
  }

  .DayPicker-Month {
    //box-sizing: border-box;
    border-collapse: separate;
    border-spacing: 0 getVw(4);
    //border-spacing: -10px ;
    margin: 0;

    &:not(:last-child) {
      margin-right: getVw(30);
    }

    &:not(:first-child) {
      margin-left: getVw(30);
    }

    font-style: normal;
    font-weight: normal;
    font-size: getVw(12);
    //line-height: getVw(12);

    color: rgba(#FFF, 0.5);

    .DayPicker-WeekdaysRow {
      font-weight: normal;
      font-size: getVw(9.5);
    }
  }


  .DayPicker-Day {
    box-sizing: border-box;
    padding: getVw(3);

    //border: getVw(1) solid transparent;

    align-self: center;

    border-radius: 0 !important;
    min-width: getVw(32);
    height: getVw(26);
    transition: .15s ease-out;

    &:focus {
      outline: getVw(1) solid $main_orange !important;
      outline-offset: - getVw(3);
    }

    &.DayPicker-Day--disabled {
      &:focus {
        outline-color: transparent !important;
        outline-offset: 0;
      }
      &:not(.DayPicker-Day--outside) {
        color: rgba(#FFF, 0.25);
      }
    }
  }

  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside1):hover {
    background-color: $main_orange !important;
    border-radius: getVw(4) !important;
  }

  .DayPicker-Day--today {
    position: relative;

    color: rgba(#FFF, 0.5);

    &::before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      border: getVw($current-day-border-width-units) solid;

      opacity: 0.2;
      border-radius: getVw(4);

      z-index: 1;
      transition: .15s ease-in-out;
    }
  }

  .DayPicker-Day--selected {
    &.DayPicker-Day--today {
      &::before {
        $height-diff-units: 4;
        top: - getVw($height-diff-units);
        height: getVw($day-item-height-units + 2*($height-diff-units + $current-day-border-width-units));
      }
    }
  }

  .DayPicker-WeekdaysRow {
    font-size: getVw(9);
    line-height: getVw(20);
  }

  .DayPicker-Day--outside {
    color: rgba(#FFF, 0.1);
  }

  //.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
    font-weight: 600;
    background-color: $main_orange !important;
  }

  /*
  .DayPicker-Day--selected.DayPicker-Day--outside {
    font-weight: normal;
    color: rgba(#FFF, 0.5);
  }
  */

  .DayPicker-Day--start {
    border-top-left-radius: getVw(4) !important;
    border-bottom-left-radius: getVw(4) !important;
  }

  .DayPicker-Day--end {
    border-top-right-radius: getVw(4) !important;
    border-bottom-right-radius: getVw(4) !important;
  }
}

.mobile-layout
.Selectable {
  .DayPicker-Month {
    &:not(:last-child) {
      margin-right: 0;
    }

    &:not(:first-child) {
      margin-left: 0;
    }
  }
}
