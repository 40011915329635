@import "../../../assets/styles/variables";

.report-maintenance {
  &-wrapper {
    background-color: $main_white;
    flex: 1;
    //display: flex;
    //flex-direction: column;

    .mobile-layout & {
      padding: 20px 0;
    }

    &--prompt {
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }

  &-controls {
    margin: 20px 20px;
  }

  &-tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .mobile-layout & {
      flex-direction: column;
      align-items: center;
      flex-wrap: nowrap;
    }

    &-column {
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &-tab-block-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &-summary-tabs {
    $this: &;

    &-small {
      margin-top: 16px;
      align-self: stretch;
      display: flex;

      #{$this}-button {
        flex: 1;
        display: flex;
        justify-content: center;
      }
    }

    .mobile-layout & {
      margin-top: 20px;
    }

    &-button {
      display: inline-flex;
      align-items: center;
      padding: 8px 6px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 8px;
      line-height: 8px;
      text-transform: capitalize;
      text-align: center;
      background: $dividers;
      color: $inactive;
      cursor: pointer;

      .mobile-layout & {
        padding: 6px 14px;
        font-size: 12px;
        line-height: 12px;
        width: 120px;
      }

      &, &:focus {
        border: none;
        outline: none;
      }


      &-active {
        color: $main_white;
        background: $main_black;
      }

      &-total {
        border-radius: 2px 0 0 2px;
      }

      &-average {
        border-radius: 0 2px 2px 0;
      }
    }

    &-svg{
      .mobile-layout & {
        width: 25px;
        height: 25px;
      }
      &-active {
        path {
          fill: $main_white;
        }
      }
    }
  }

  &-tab-block {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 20px;
    color: $inactive;
    letter-spacing: -0.078px;
    cursor: pointer;

    &, &:focus {
      background: none;
      border: none;
      outline: none;
    }

    &-active {
      font-weight: 600;
      color: $main_black;
      letter-spacing: -0.084px;
    }

    .mobile-layout & {
      font-size: 12px;

      letter-spacing: -0.084px;

      &-active {
        letter-spacing: -0.078px;
      }
    }
  }

  &-tab-separator {
    background-color: $inactive;
    width: 1px;
    height: 10px;
    margin: 0 8px;

    .mobile-layout & {
      margin: 0 12px;
    }
  }
}