@import "../../../assets/styles/variables";

$color: $main_white;
$bg-color: $main_black;
$bg-hover-color: $main_orange;

.Button {
  &_color_main-primary {
    color: $color;
    &[disabled] {
      color: rgba($color, 0.5);
    }

    &.Button_variant_contained {
      background-color: $bg-color;
      &[disabled] {
        background-color: rgba($bg-color, 0.5);
      }

      &:not([disabled]):hover {
        background-color: $bg-hover-color;
      }
    }
  }
}
