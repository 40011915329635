  .modal_invoices_orders { 
    position: relative;
    overflow-y:scroll;
    min-width: 60vw;
    min-height: 40vh;
  
    padding: 20px;
    border-radius: 12px;
    background-color: white;

    @media (max-width: 1060px) {
      min-width: 80vw;
    }

    &-title {
      background: gray;
      border-radius: 5px;
      padding: 5px 20px 5px 20px;
      color: white;
      font-size: 20px;
      font-weight: bold;
    }

    .paid-status {
      color: #48d02a;
      text-transform: uppercase;
    }

    &-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
    
        & button {
          height: 40px;
          width: 40px;
          background: #4d4646;
          border-radius: 10px;
          color: white;

          @media print {
            display: none;
          } 
          
        }
    
        & button:hover {
          background: grey;
          transition: ease-in .3s;
        }
      }

      &-table {
        min-width: auto;

    border-collapse: collapse;
    width: 100%;

    & th {
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: center;
        background-color: #494646;
        color: white;
        text-align: left;

        border: 1px solid #ddd;
        padding: 8px;
    }

    & td {
        border: 1px solid #ddd;
        padding: 8px;
    }

    & td tr:nth-child(even) {
        background-color: transparent;
      }

    & tr:nth-child(even) {
        background-color: #f2f2f2;
    }  
      }
  }
  
  .modal_invoices_orders::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

///////////////////////

.summary {
    margin: 20px 0 10px 0;

  p:last-child {
    margin-bottom: 5px;
  }

  &_block {

    span {
      color: orange;
      font-weight: bold;
      margin-bottom: 10px;
    }

    div {
      padding: 10px;
      background-color: #4a4a4a;
      color: white;
      margin-top: 10px;
      border-radius: 5px;
    }

  }

  &_support {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    align-items: center;
  }
}

.link {
  color: #F26F22;
}

.print-button {
    align-items: flex-start;
    width: 100px;
    padding: 5px 0;
    border-radius: 5px;
    color: white;
    background-color: #4a4a4a;

    @media print {
      display: none;
    }

  }

.invoice-pdf {
    min-height: 70vh;
    border-radius: 12px;
    background-color: white;
    width: 100%;
    border: none;
}
