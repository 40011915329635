@import '../../../../assets/styles/variables';

.modalContent {
  background: $main_white;
  padding: 20px 20px 16px;
  width: 432px;
  max-width: 100%; // to cover mobile resolutions
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 18.75px;
    color: $main_black;
    margin: 0;
  }

  .form {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  .label {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin: 0;

    // field name
    font-weight: 300;
    font-size: 11px;
    line-height: 16px;
    color: $main_black;

    .input {
      border: none;
      width: 100%;
      height: 44px;
      padding: 8px 12px;
      border-radius: 2px;
      background: $bg_input;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: $main_black;

      &::placeholder {
        color: $inactive2;
      }
    }

    .errorMessage {
      color: $main_red;
      font-size: 12px;
      margin: 0;
    }
  }

  .buttons {
    padding: 16px 0 0;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid $dividers;
    column-gap: 8px;

    .cancelButton,
    .createButton {
      min-width: 64px;
      height: 28px;
      padding: 8px 12px;
      border-radius: 2px;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;

      &:disabled {
        cursor: not-allowed;
        opacity: 0.8;
      }
    }
    .cancelButton {
      border: 1px solid $dividers;
      color: $inactive;
    }

    .createButton {
      color: $main_white;
      background-color: $main_orange;
    }
  }
}