@keyframes FenceFadeIn {
  0% {
    transform: translateY(100vh)
  }

  100% {
    transform: translateY(0)
  }
}

@keyframes FenceFadeOut {
  0% {
    transform: translateY(0)
  }

  100% {
    transform: translateY(100vh)
  }
}

.fence-modal {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 4px;
  max-height: 100%;
  width: 228px;
  &-content {
    display: flex;
    max-height: 100%;
  }
  &-fullWidth {
    width: 100%;
  }
  &-white {
    background-color: #fff;
  }
  &-transparent {
    background-color: transparent;
  }
}

.fence-mobile-modal {
  position: absolute;
  z-index: 90;
  width: 100%;
  background-color: #fff;
  height: 100%;
  animation: 1s ease-in-out FenceFadeIn;
  &-hide {
    animation: 1s ease-in-out FenceFadeOut;
  }
  &-content {
    display: flex;
    max-height: 100%;
    width: 100%;
  }
}