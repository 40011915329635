.modal__service {
  position: relative;
  min-height: 100px;
}

.modal__main {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 1060px) {
    flex-wrap: wrap;
  }
}

.cancellation-btn {
  margin-top: 30px;
}
