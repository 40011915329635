@import "../../../../assets/styles/variables.scss";
.report-table-fix {
  table-layout: fixed;
}
.trip-date-wrap {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  font-size: 11px;
  line-height: 12px;
  padding: 10px 12px;
  .mobile-layout & {
  	padding: 12px;
  }
}
.start-date-icon {
  fill: green;
}
.end-date-icon {
  fill: red;
}
.trip-date {
  display: flex;
}

.report-table-wraper {
  margin-bottom: 30px;

  .scrollbar-container {
    display: flex;
  }

  .pagination {
    margin: 20px auto;
    list-style: none;
    display: flex;
    font-size: 12px;
    color: #111;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    flex-wrap: wrap;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 5px;
      padding: 0 3px;
      width: 25px;
      flex: 0 0 25px;
      height: 25px;
      cursor: pointer;
      & a{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .active {
      background-color: #FF7C02;
      border-radius: 4px;
      font-weight: bold;
      outline: none;
    }

    .disabled {
      &:after {
        background: transparent;
      }
    }
  }
}
table.report-table {
  margin-bottom: 10px;
  &.report-table-travel {
    width: 100%;
  }

  tbody{
    text-align: start;
    border: 1px solid #ECECEC;
    .trip-static-header th {
      padding: 8px 0;
      padding-left: 12px;
      background-color: $bg_input;
      font-weight: normal;
    }
    tr {
      td {
        padding: 8px 0;
        padding-left: 12px;
        text-align: start;
		.mobile-layout & {
			padding: 0;
			background: $main_white !important;
			border-bottom: 1px solid $dividers;
		}
		}
		}
		}
}
.trip-wraper {
  display: flex;
  justify-content: space-evenly;
  &.open {
  	flex-direction: column;
  	align-items: space-evenly;
  }

  .mobile-layout & {
  	flex-direction: column;
  	align-items: space-evenly;
  	padding: 24px;

  	&>div {
  		padding: 6px 0;

  		&:first-child {
  			padding-top: 0;
  		}

  		&:last-child {
  			padding-bottom: 0;
  		}
  	}
  }

  &>div {
  	flex-grow: 1
  }
}
.address-actions-wrapper {
  display: flex;
  width: fit-content;
  align-items: center;
  &.open,
  .mobile-layout & {
    flex-grow: 1;
    justify-content: space-between;
  }
}
.report-point-table-wrap {
  font-size: 11px;
  line-height: 12px;

  width: 100%;
  max-height: 400px;
  overflow: auto;
}
.report-point-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 700px;
  height: 100%;

  .trip-static-header-wrapper{
    padding-top: 10px;
    background-color: $main_orange;
    position: sticky;
    top: 0;

    .trip-static-date, .trip-time-clock-date {
      text-align: center;
      font-size: 16px;
      padding-bottom: 5px;
      border-bottom-style: solid;
      border-bottom-width: 2px;
      border-bottom-color: rgba(0, 0, 0, 0.089);
    }

    .trip-static-header {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr 2fr 1fr 1fr 1fr;
      padding: 10px;
    }
  }

  .trip-time-clock-header {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 10px;
  }

  .trip-time-clock-row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 10px;

    .trip-time-clock-row-item {
      display: flex;
      align-items: center;
    }
  }

  .trip-static-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr 2fr 1fr 1fr 1fr;
    padding: 10px;

    .trip-static-row-item {
      display: flex;
      align-items: center;
    }
  }
}

.permissions-table-container {
  width: 100%;

  h2 {
    margin-bottom: 20px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;

    th, td {
      border: 1px solid black;
      padding: 8px;
      text-align: center;
    }

    th {
      background-color: #d9d8d8;
    }

    td {
      background-color: #fff;
    }

    tbody tr:nth-child(even) {
      background-color: #f9f9f9;
    }
  }

  .no-records {
    font-size: 20px;
  }
}
