@import '../../../../assets/styles/variables';
@import '../tagsMixins';

@mixin bold_text {
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0;
  color: $main_black;
}

.tagsListItem {
  @include tagsTableRow;
  background: $main_white;
  align-items: center;
  min-height: 56px;

  &:hover {
    background: $bg_input;

    .favoriteButton {
      opacity: 1;
    }
  }

  .tag {
    display: flex;
    align-items: center;
    column-gap: 4px;

    .bellIcon {
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      color: $main_black;
    }

    .tagName {
      @include bold_text;
      @include textRows;

      margin: 0;
    }
  }

  .favoriteButton {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 16px;
    height: 16px;
    opacity: 0;

    .favoriteStar {
      width: 100%;
      height: 100%;
      color: $inactive3;
    }

    &.favorite {
      opacity: 1;
      .favoriteStar {
        color: $main_orange;
      }
    }
  }

  .device {
    text-align: center;
    margin: 0;
    @include bold_text;
  }

  .options {
    display: flex;
    column-gap: 8px;
    align-items: center;
    justify-content: flex-end;

    .optionItem {
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;

      .optionIcon {
        width: 100%;
        height: 100%;
        color: $inactive;
        fill: $inactive;
      }
    }
  }
}
