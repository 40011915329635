@import "../../../fences.mixins";

.taggedItemsMobile {
  @include fenceMobile;
}

.buttonsBlock {
  @include fenceButtonsBlock(12px 0 0 16px);
  & > .buttonsBlockWhiteButton {
    @include fenceButton(white, black, 94px);
    outline: none;
  }
  & > .buttonsBlockBlackButton {
    @include fenceButton(black, white, 94px);
  }
}