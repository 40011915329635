.add-billing-card {
  position: relative;
  min-height: 150px;
  background-color: #3b3b3b;
  border-radius: 8px;
  overflow: hidden;
  max-width: 270px;
  min-width: 270px;
}

#my-sample-form {
  padding: 0 0 60px;
}

.card-number-box {
  label {
    font-size: 12px;
    margin-bottom: 0;
    line-height: 12px;
    padding: 13px 16px 0;
  }

  #card-number {
    padding: 0 5px;
    height: 25px;
    margin: 5px 15px 0;
    width: auto;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }
}
.bottom-box {
  display: flex;
  justify-content: left;
  margin-top: 24px;
  .expires {
    display: flex;
    flex-direction: column;
    padding-left: 16px;
    label {
      color: white;
      font-size: 10px;
      margin-bottom: 0;
      line-height: 1;
      text-transform: uppercase;
    }

    #expiration-date {
      height: 20px;
      width: 55px;
      margin-top: 5px;
      border-bottom: 1px solid rgba(white, 0.4);
    }
  }
  .cvv-box {
    display: flex;
    flex-direction: column;
    margin-left: 27px;
    label {
      color: white;
      font-size: 10px;
      margin-bottom: 0;
      line-height: 1;
      margin-right: 7px;
      text-transform: uppercase;
    }

    #cvv {
      width: 25px;
      height: 20px;
      margin-top: 5px;
      border-bottom: 1px solid rgba(white, 0.4);
    }
  }
}

.form-box-btn {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
}

.btn-cancel {
  width: 50%;
  border-top: 1px solid rgba(white, 0.2);
  background-color: gray;
  color: rgba(white, 0.4);
  text-align: center;
  margin: 0;
  padding: 7px 0;
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
  transition: all 0.3s ease-in;
  &:hover {
    cursor: pointer;
    color: white;
    border-top: 1px solid rgba(white, 0.1);
    background-color: rgba(white, 0.2);
  }
}

input[type="submit"] {
  border: none;
  outline: none;
  border-top: 1px solid rgba(white, 0.1);
  background-color: rgba(white, 0.2);
  width: 50%;
  margin: 0;
  padding: 7px 0;
  color: white;
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
  transition: all 0.3s ease-in;
  &:hover {
    color: rgba(white, 0.4);
    border-top: 1px solid rgba(white, 0.2);
    background-color: transparent;
  }
}
