@import "../../assets/styles/variables";
@import "variables";

.Button {
  box-sizing: border-box;

  padding: getVw(8) getVw(14);
  background-color: transparent;
  border: 0;
  border-radius: getVw(2);
  color: rgba($main_white, .4);

  font-style: normal;
  font-weight: 600;
  font-size: getVw(11);
  line-height: getVw(11);

  text-decoration: none;

  &:not([disabled]):hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.04);
    color: rgba(0, 0, 0, 0.85);
  }

/*  &:focus {
    color: lime;
  }*/
}
