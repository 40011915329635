@import '../../../assets/styles/variables';

.selectedTagsContainer {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  font-weight: 300;
  font-size: 11px;
  line-height: 12px;
  color: $main_black;

  p {
    margin: 0;
  }

  .selectedCount {
    font-weight: 500;
  }
}

.searchInput {
  margin-top: 12px;
}

.checkboxesList {
  margin-top: 8px;
}

.selectedTagsSection {
  margin-top: 16px;
}

.infoMessagesSection {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  &:has(*) {
    margin-top: 16px;
  }
}

.actionButtons {
  // remove parent padding for left, right and bottom sides
  margin: auto -16px -16px;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 8px;
  background: $main_white;

  .cancelButton,
  .saveButton {
    height: 28px;
    padding: 8px 14px;
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    text-align: center;
    text-transform: capitalize;
    flex: 1;
    border-radius: 2px;
  }

  .cancelButton {
    border: 1px solid $inactive2;
    color: $inactive;
  }

  .saveButton {
    background: $main_orange;
    color: $main_white;
  }
}

:global(.full-size) {
  .cancelButton,
  .saveButton {
    max-width: 80px;
  }
}