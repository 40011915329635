@import '../../../assets/styles/variables';

.selectedItemsSection {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  .sectionTitle {
    font-weight: 300;
    font-size: 11px;
    line-height: 12px;
    color: $main_black;
    margin: 0;
  }

  .selectedItemsList {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
}