.card {
  min-width: 270px;
  height: 150px;
  overflow: hidden;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: bottom left;
  
  &[credit-type="bankAccount"] {
    background-image: url('../../../../../assets/svg/orange_wave.svg');
  }

  &[credit-type="creditCard"] {
    background-image: url('../../../../../assets/svg/black_wave.svg');
  }
  
  &:hover {
    .card__action {
      opacity: 1;
    }
  }

  &.card-primary {
    background-color: #ec6a1d;
  }

  &.card-backup {
    background-color: #3b3b3b;
  }

  &__box {
    position: relative;
    min-height: 150px;
    padding: 16px 16px 25px;
  }

  &__name {
    font-size: 12px;
    padding-left: 5px;
  }

  &__number {
    position: absolute;
    font-size: 14px;
    top: 50%;
    left: 50%;
    letter-spacing: 2px;
    font-weight: 500;
    transform: translateY(-50%) translateX(-50%);
  }

  &__expires {
    position: absolute;
    opacity: 0.2;
    bottom: 20px;
    left: 15px;
    z-index: 2;
  }

  &__active-bottom {
    position: absolute;
    bottom: 20px;
    left: 15px;
    z-index: 2;
    padding-left: 9px;
    text-transform: uppercase;
  }

  &__action {
    cursor: pointer;
    position: absolute;
    transition: all 0.3s ease-in;
    top: 12px;
    right: 16px;
    opacity: 0;

    svg {
      fill: white;
    }
  }

  .wave-icon {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
