@import "../../../assets/styles/variables.scss";

a {
    color: inherit;
    &:hover {
        text-decoration: none;
        color: inherit;
    }
}

.disabled {
    position: relative;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 100;
        background-color: $inactive;
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.icon-xsm {
    width: 10px;
    height: 10px;
}

.icon-12px {
    width: 12px;
    height: 12px;
}

.icon-17px {
    width: 17px;
    height: 17px;
    fill: black;
}

.icon-sm {
    width: 12px;
    height: 12px;
}

.icon-16px,
.icon-smd {
    width: 16px;
    height: 16px;
}

.icon-20px {
    width: 20px;
    height: 20px;
}

.icon-md {
    height: 18px;
    width: 18px;
}

.icon-lg {
    width: 20px;
    height: 20px;
}


.capitalize {
    text-transform: capitalize;
}

.nowrap {
    white-space: nowrap;
}

.weight-normal {
    font-weight: normal;
}

label label {
    margin: 0;
}

.align-center {
    display: flex;
    align-items: center;
}
.color-inactive {
    color: $inactive;
}

.cursor-pointer {
    cursor: pointer;
}