@import "../../../../../assets/styles/variables";

.ExtraInfo-inputs {
	flex-direction: column;
	margin-bottom: 0;
}

.ExtraInfo-input {
	label {
		margin-bottom: 0;
		width: 50%;
	}

	input {
		width: 40%;

		&:focus,
		&:focus-visible {
			border-radius: 2px;
			border: 2px solid #f7f7f7;
		}
	}
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
}

.AddNewLabel {
	width: 100%;
	margin: 0 auto;
	padding: 0;
	font-size: 14px;
	button {
		border: none;
		background: #f7f7f7;
		text-decoration: underline;
		cursor: pointer;
		padding: 0;
	}
}

.ExtraInfoNew {
	display: flex;
	flex-direction: column;
	height: auto;
	border-radius: 2px;
	background-color: #F6F6F6;
	padding: 10px;
	margin-top: 8px;
	position: relative;

	&>p {
		text-align: center;
	}

	& p,
	label {
		font-size: 13px;
		line-height: 20px;
	}

	div {
		span {
			font-size: 11px;
			font-weight: 600;
			height: 12px;
		}

		svg {
			cursor: pointer;
			width: 16px;
			height: 16px;
			margin-left: 4px;
		}
	}

	input {
		border: none;
		background-color: #fff;
		border-radius: 2px;
		font-size: 12px;
		height: 22px;
		padding: 1px 4px;
		width: 50%;
	}

	&__add-btn {
		&:focus {
			outline: none;
		}
		background-color: transparent;
		outline: none;
		border: none;
		display: flex;
		justify-content: center;
	}

	&__create-btn {
		cursor: pointer;
		border: none;
		font-size: 14px;
		&:hover {
			background-color: $main_black;
			color: $main_white;
		}
	}

	&__actions {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__modal {
		position: absolute;
		background-color: $main_gray;
		border: 1px solid $inactive2;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

.add-labels,
.create-new-label {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
}

.add-labels {
	padding: 10px 0;
	max-height: 500px;
	&__content {
		padding: 0 10px
	}

	&__list {
		padding: 0;
		list-style: none;
		width: 100%;
	}

	&__item {
		border-radius: 2px;
		margin: 5px 0;
		text-align: center;
		cursor: pointer;
		background-color: $main_white;
		&:hover {
			background-color: $main_black;
			color: white;
		}
	}

	&__header {
		display: flex;
		justify-content: flex-end;
		padding: 5px 0;
	}

	&__blank {
		display: flex;
		justify-content: center;
	}

	&__close-icon {
		width: 8px;
	}

	&__close-btn {
		&:active {
			outline: none;
		}
		outline: none;
		border: none;
		background-color: transparent;
		cursor: pointer;
		display: flex;
	}
}

.create-new-label {
	display: flex;
	flex-direction: column;
	padding: 10px;
	> * {
		margin: 5px 0;
	}

	&__create-btn {
		border: none;
		cursor: pointer;
		&:hover {
			background-color: black;
			color: white;
		}
	}

	&__header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		p {
			margin: 0;
		}
	}

	input {
		width: 100%;
	}

	&__close-btn {
		&:active {
			outline: none;
		}
		outline: none;
		border: none;
		background-color: transparent;
		cursor: pointer;
		display: flex;
	}

	&__close-icon {
		width: 8px;
	}
}