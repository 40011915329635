:root {
  --base-container: 1440;
  --neutral-icon-color: #878889;
}

@media only screen and (max-width: 768px) {
  :root {
    --base-container: 400;
  }
}

@media only screen and (min-width: 1600px) {
  :root {
    --base-container: 1800;
  }
}

@function getVw($px) {
  @return calc(#{$px} / var(--base-container) *100vw);
  //@return $px + px;
}

@mixin hideScrollBar {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

$main_orange: #FF7C02;
$main_red: #ff4642;
$main_yellow: #ffeb3b;
$main_black: #111;
$main_white: #FFFF;
$main_gray: #F8F8F8;

$inactive: #878889;
$inactive2: #B9B9B9;
$inactive3: #B6B6B6;

$dividers: #ECECEC;

$snow: #F9F9F9;
$whiteSmoke: #F8F8F8;
$white: #ffffff;
$nero: #222222;

$border_gray: #F0F0F0;

$font-gray: #B9B9B9;
$bg_input: #F7F7F7;
$bg_gray: #F8F8F8;
$bg_tag_label: #E4E4E4;

$secondary_gray: #ECECEC;

$deviders: #ECECEC;

$denied: #B00808;
$granted: #25991A;

$tooltip_background: #6E6E6E;

$primary-color: #2f76c3;
$mediumGray: #999;

@mixin textRows($rows: 3) {
  white-space: break-spaces;
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $rows;
  line-clamp: $rows;
  -webkit-box-orient: vertical;
}

@mixin textOneLine {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}