.form-btn {
  padding: 10px 30px;
  background-color: orangered;
  border-radius: 5px;
  color: white;
  min-width: 260px;
}

.error-message {
  position: absolute;
}