@import "../../../../assets/styles/variables";

.DeviceButtonsHorizontalScroll {
  height: max-content;
  flex-shrink: 0;

  :global(.ps__thumb-x) {
    background-color: $main_black !important;
    height: 2px;
  }
}

.DeviceButtons {
  display: grid;
  grid-template-columns: repeat(auto-fill, 88px);
  grid-auto-flow: column;
  white-space: nowrap;
  gap: 8px;
  margin-top: 15px;
  height: 60px;

  * {
    flex-shrink: 0;
  }

  &:not(:has(*)) {
    margin-top: 0;
    height: 0;
  }

  .DeviceButton {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    width: calc(33.333% - 10px);
    min-width: 88px;
    padding: 8px;
    gap: 8px;
    background-color: $white;
    cursor: pointer;
    height: 60px !important;

    img {
      height: 20px;
    }

    span {
      color: $inactive;
      font-size: 10px;
      font-weight: 400;
    }
  }

  .OfflineModal {
    background-color: $white;
    padding: 30px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;

    button {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
}
