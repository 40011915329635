.wrapper {
  padding-top: 20px;
  padding-bottom: 20px;

  button {
    width: 100%;
    height: 40px;
    background-color: #ff7c02;
    color: #fff;
  }

  .completedMessageWrapper {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    gap: 30px;

    .icon {
      max-width: 70px;
      min-width: 40px;
    }
  }
}
