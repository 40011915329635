.captcha-wrapper{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.507);
    overflow-y: auto;
    z-index: 100;
    display: flex;
    justify-content: center;
    padding: 50px;

    .captcha{
        display: flex;
        flex-direction: column;
        max-height: 200px;
        padding: 10px 20px;
        background-color: #FFF;
        border-radius: 10px;

        .title{
            font-size: 15px;
            text-align: center;
            margin: 10px 0;

        }

        .close{
        align-self: flex-end;
        }
    }
}