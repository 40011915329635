@import "../../../../assets/styles/variables.scss";

.DvirItemHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h2 {
    margin: 0;
  }
}

.UpdateDvirContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 0 auto 20px;

  .DvirHeadingsTable {
    border-collapse: collapse;
    background: $white;

    thead {
      th {
        padding: 4px 12px;
        background-color: #FF7C02;
        color: $main_black;
        text-align: center;

        &:first-child {
          width: 25%;
          min-width: 150px;
        }
      }
    }

    tbody {
      tr {
        height: 70px;

        td {
          padding: 8px 12px;
          border-bottom: 1px solid #333;
          border-top: 1px solid #333;
          color: #444;
          background: #f8f9fa;
          text-align: center;

          .HeadingStatus {
            text-align: center;
          }

          &:first-child {
            width: 75%;
            min-width: 150px;
            text-align: center;
            border-right: 1px solid #333;
          }
        }

        .HeadingValuePicker {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          background-color: $white;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
          border-radius: 10px;
          border: 1px solid $primary-color;
          z-index: 10;

          .HeadingValue {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px;
          }
        }
      }
    }
  }

  .UpdateDvirForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    .UpdateDvirOdometer {
      padding: 8px;
      border: 1px solid $main_orange;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      text-align: center;
    }

    .UpdateDvirNotes {
      min-width: 300px;
      width: 50vw;
      padding: 12px;
      border: 1px solid $main_orange;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      resize: none;
    }

    .UpdateDvirBtn {
      padding: 8px 12px;
      background-color: $main_black;
      color: $whiteSmoke;
      border-radius: 4px;
      margin-top: 10px;

      &:hover {
        color: $main_black;
        background-color: $whiteSmoke;
        border: 1px solid $main_black;
      }
    }
  }
}
