@import '../../../assets/styles/variables';

.content {
  padding: 12px;
  background: rgba($denied, 0.1);
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  border-radius: 2px;


  .warnHeader {
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  .warnTitleBlock {
    color: $denied;
    display: flex;
    align-items: center;
    column-gap: 4px;
    flex: 1;

    .warnIcon {
      width: 16px;
      height: 16px;
      fill: currentColor;
      flex-shrink: 0;
    }

    .warnTitle {
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.5px;
      flex: 1;
      margin: 0;
    }
  }

  .closeButton {
    width: 16px;
    height: 16px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    .closeIcon {
      width: 16px;
      height: 16px;
      color: $main_black;
    }
  }

  .message {
    margin: 0;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $main_black;
  }
}
