@import "../../fences.mixins";

.Filters {
  .ButtonsBlock {
    @include fenceButtonsBlock(12px 0 0 16px);
    &-link {
      flex: 1;
    }
    .BlackButton {
      @include fenceButton(black, white, 100%);
    }
  }

  &-Mobile {
    @include fenceMobile;
  }

  .FiltersBox {
    display: flex;
    flex-direction: column;
    padding: 16px 0 0 16px;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 5px 0;
      height: getVw(12);

      span {
        width: 120px;
        line-height: 12px;
        opacity: 0.6;
        cursor: default;
        color: black;
        font-size: 11px;

        &:hover {
          opacity: 1;
        }
      }

      svg {
        width: 16px;
        height: 16px;
        opacity: 0.2;
        margin-right: 8px;
        cursor: pointer;
        display: flex;
        align-items: baseline;

        &:hover {
          opacity: 1
        }
      }

    
    }
  }
}

.delete-filter {
  width: 18px;
  height: 18px;
  fill: #ff4642;
  display: flex;
  align-items: baseline;
  cursor: pointer;
}