@import "../mixins.scss";

.history {
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .calendar {
    position: relative;
    padding: 10px;
    min-height: 315px;
    min-width: 310px;

    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__list {
    position: relative;
    width: 100%;
    max-height: 315px;
    min-height: 200px;
    padding: 10px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &__header {
      display: flex;
      padding: 0 10px;

      & div {
        flex-basis: calc(100% / 3);
        text-transform: uppercase;
        color: rgba(34, 34, 34, 0.5);
        font-size: 16px;
        font-weight: 700;
      }
    }

    &__row {
      display: flex;
      align-items: center;
      padding: 10px;
      color: black;
      margin-top: 10px;
      font-size: 14px;
      cursor: pointer;

      & div {
        flex-basis: calc(100% / 3);
      }

      .chooseChannel {
        display: flex;
        align-items: center;

        &_radio {
          @include radioBtn;
          margin-left: 10px;
          accent-color: #0697d4;
        }
      }
    }

    &__row:nth-child(2n-1) {
      background-color: #f7f7f7;
      border-radius: 5px;
    }
  }
}

.tips {
  background: #f7f7f7;
  padding: 20px;
  border-radius: 5px;

  &_list {
    display: flex;
    align-items: center;
    & div {
      height: 10px;
      width: 10px;
      border-radius: 5px;
      margin-right: 10px;
    }
  }
}

.emptyHistory {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: rgba(34, 34, 34, 0.5);
  font-weight: 600;
}

.viewButton {
  @include viewButton;
}

.loader {
  @include loader
}


.customScroll {
  &::-webkit-scrollbar {
    display: block;
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    margin: 15px 0 0 0;
    border-radius: 20px;
    background-color: #8080800f;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: #aaaaaa33;
    border-radius: 20px;
  }
}