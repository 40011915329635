.actionRow {
  height: 29px;
  display: flex;
  justify-content: flex-start;
  background-color: rgba(47, 118, 195, .15);
  border-bottom: 1px solid #999;
  padding: 3px 8px;
}

.actionBackBtn {
  margin-right: 8px;
}

.actionsList {
  flex: 1;
  text-align: right;
}

.actionIcon {
  cursor: pointer;
}

.actionRowTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}