.maintenance-table {
  width: 100%;

  &-head {
    &-row {
      &-itm {
        padding: 8px 0 8px 12px;

        &:last-child {
          padding-right: 12px;
        }
      }
    }
  }

  &-body {
    &-row {
      &-itm {
        padding-left: 12px;

        &:last-child {
          padding-right: 12px;
        }
      }
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
  }
}