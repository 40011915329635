.summary-report-block {
	margin: 36px 20px 50px;
}

.summary-table {
	border: 1px solid #ECECEC;
	border-radius: 4px;
	margin-bottom: 24px;

	&__content {
		padding: 24px 24px 8px;
	}

	&__col {
		transition: all 0.3s ease;
	}
		
	&__item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-grow: 1;
		font-size: 11px;
		line-height: 12px;

		&__title {
			font-weight: 300;
		}

		&__value {
			font-weight: 600;
		}
	}

	&__header {
		padding: 16px 12px;
		border-bottom: 1px solid #ECECEC;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.but {
			margin-left: 10px;
		}
	}

	&__title {
		font-size: 14px;
		font-weight: 600;
		margin-bottom: 0;
	}

	&__buttons {
		display: flex;
	}
}
	
.dashboard-block {

	&.open {

		.summary-table {

			&__content {
				gap: 20px;
			}
		}
	}

	&.full-size {

		.summary-table {

			&__content {
				gap: 56px;
				display: flex;
			}

			&__col {
				width: 50%;
			}
		}
	}
}

@media screen and (max-width: 1279px) and (min-width: 769px) {
	.summary-table {

		&__buttons {
			margin-bottom: 10px;
		}

		&__header {
			flex-direction: column-reverse;
			align-items: start;

			.but {
				margin-left: 0;
				margin-right: 10px;
			}
		}
	}
}
@media screen and (max-width: 767px) {
	.dashboard-block.full-size {

		.summary-table {

			&__content {
				display: block;
			}
			&__col {
				width: 100%;
			}
		}
	}

	.summary-report-block {
		margin: 20px 0 90px;
	}
}