@import "./variables";


.Tabs {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  flex-wrap: nowrap;
  padding-left: 0;
  margin-bottom: 0;

  box-sizing: border-box;

  &-Tab {
    $font-size-units: 11;

    position: relative;
    list-style: none;
    padding: getVw($tab-height-units - $font-size-units * 2) getVw($tab-horizon-border-units);

    z-index: $active-tab_z-index - 1;

    display: flex;
    align-items: center;

    height: getVw($tab-height-units);
    font-style: normal;
    font-weight: bold;
    font-size: getVw($font-size-units);
    line-height: getVw($font-size-units);

    cursor: pointer;
    //background-color: $theme-light-tab-bg-color;
    box-shadow: getVw(18) getVw(19) getVw(31) rgba(#000, 0.1);

    > span {
      opacity: 0.2;
      transition: .3s ease-in-out;
    }

    &:hover > span {
      opacity: 1;
      //text-shadow: 0 0 10px $main_orange;
    }

    &_active {
      background-color: $theme-light-tab-active-bg-color;

      z-index: $active-tab_z-index;

      > span {
        opacity: 0.8;
      }
    }
  }
}

.Tabs-TabContent {
  background-color: $theme-light-tab-active-bg-color;
  position: relative;
  z-index: $active-tab_z-index;
  width: 100%;
}

