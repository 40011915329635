@import "../../../../assets/styles/variables";
.create-report-container.dashboard-open {
  height: 900px;
}

.mobile-layout .create-report-container {
	height: calc(100vh - 27px - 83px);
}

.create-report  {
  padding: 20px 20px 0px 20px;
  display: flex;
  justify-content: space-between;
	.mobile-layout & {
		flex-direction: column-reverse;
		padding: 0;

		&>h5 {
			text-align: center;
			margin-top: 27px;
			margin-bottom: 0;
			padding: 26px 0 22px;
			position: fixed;
			top: 0;
			z-index: 1;
			width: calc(100% - 40px);
			background: $main_white;
			border-bottom: 1px solid $dividers;
		}
	}

  .create-report-col {
    flex-grow: 1;
    flex-basis: 50%;
	.mobile-layout & {
		font-size: 11px;
		line-height: 12px;

		&:last-of-type {
			margin-top: calc(56px + 32px);
		}

		.create-report-select-section-title {
			font-size: 11px;
			line-height: 16px;
			margin: 20px 0 8px 0;
		}
	}

    .create-report-row {
      display: flex;
      flex-grow: 1;
      padding: 12px 0;
      justify-content: space-between;
      .create-report-row-title {
        flex-grow: 1;
        width: 50%;
        color: $inactive;
      }
      .create-report-row-value {
        flex-grow: 1;
        text-align: start;
        width: 50%;

				&--clickable {
          cursor: pointer;
        }

		    .mobile-layout & {
			    display: flex;

					.but {
						font-size: 11px;
						line-height: 11px;
					}
	     	}
      }
    }
    .create-report-select-section {
		.mobile-layout & {
			margin-bottom: 8px;
		}

      .create-report-select-section-title {
        margin: 20px 0 8px 0;
      }
    }
  }
  .create-report-note {
    margin-top: 20px;
    margin-bottom: 10px;
	.mobile-layout & {
		font-size: 11px;
		line-height: 18px;
	}
	}

  &.dashboard-open {
    flex-direction: column;
    .create-report-details {
      order: 2;
    }
    .create-report-note {
      margin-top: 0;
    }
  }


}
.create-report-actions {
   padding: 20px;
   display: flex;
   right: 0;
   left: 0;
   justify-content: space-between;
	.mobile-layout & {
		width: 100vw;
		transform: translateX(-20px);
		border-top: 1px solid $dividers;
		padding: 16px 20px 0;
		margin-bottom: 32px;

		&-right {
			display: flex;
			gap: 8px;
			width: 100%;

			&>a {
				display: flex;
				width: 50%;

				&>button {
					flex-grow: 1;
				}
			}
		}
	}

   .apply-btn {
     margin-left: 5px;
   }
 }