.desktop-layout {
  display: flex;

  height: 100%;
  overflow: hidden;

  .main-content {
    position: relative;
    display: flex;
    flex: 1;

    .google-map-block {
      position: relative;
      z-index: 2;

      flex: 1;
    }
  }
}
