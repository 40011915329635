@import "../variables";

$tabs-underline-size-units: 1;
$tabs-underline-color: #2C2C2C;
$tab-active-underline-color: $main_orange;
$tab-active-underline-size: getVw(2);

.Tabs_styled_underlined {
  &.Tabs {
    justify-content: center;

    border-bottom: getVw($tabs-underline-size-units) solid $tabs-underline-color;

    .Tabs-Tab {
      box-shadow: none;
      border-bottom: getVw($tabs-underline-size-units) solid $tabs-underline-color;
      margin-bottom: getVw(-$tabs-underline-size-units);

      &::after {
        content: '';
        background: $main_orange;
        height: $tab-active-underline-size;
        position: absolute;
        bottom: -1px;

        left: 0;
        right: 100%;

        transition: .3s all ease-in-out;
      }

      &_active {
        background-color: $theme-light-tab-bg-color;

        & ~ ::after {
          left: 100%;
          right: 0;
        }

        &::after {
          left: 0;
          right: 0;
        }
      }
    }
  }

  &.Tabs_theme_dark {
    .Tabs-Tab {
      background-color: inherit;
    }

    & + .Tabs-TabContent {
      background-color: inherit;
    }
  }
}
