.mainContainer {
  .switchBlock, .cancelBlock {
    color: #333;
    border: 1px solid #ddd;
    text-align: left;
  }
  .cancelBlock {
    margin-top: 25px;
  }
  .switchHeader {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
  }
  .switchContent, .cancelContent {
    padding: 10px 16px;
  }

  .actionButton {
    width: 250px;
    color: #fff;
    text-align: center;
    padding: 10px 16px;
  }

  .cancelBtn {
    background-color: #d9534f;
    border: 1px solid #d43f3a;
  }

  .switchBtn {
    background-color: #f0ad4e;
    border: 1px solid #eea236;
  }
}