@import './mixins.scss';

.modal_header {
    position: relative;
    background: grey;
    padding: 10px 15px;
    border-radius: 5px;
    color: white;
    box-shadow: 0 3px 10px #00000033;

  @media (min-width: 768px) {
    margin-left: auto;
  }

    &__title {
      margin-bottom: 0;
    }

    &__count {
    position: absolute;
    top: -10px;
    right: -10px;
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0aaaea;
    font-size: 12px;
    border-radius: 50%;
    }

    &__close {
    height: 40px;
    width: 40px;
    background: #4d4646;
    border-radius: 10px;
    color: white;
    margin-left: auto;

      &:hover {
        background: grey;
        transition: ease-in 0.3s;
      }
    }

    &__close_ml {
      margin-left: auto;
    }
}

.modalChannels{
  background-color: #FFFFFF;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  & .buttonClose {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  & > h6{
    margin-top: 20px;
  }

  & > h3,h5{
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
  }

  & .channelsBlock{
    padding: 0;
    margin-top: 10px;
  }

  .channelItem{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;

    & .nameChannel{
      display: flex;
      align-items: center;
    }

    & ul{
      padding: 0;
    }

    & h4{
      font-size: 18px;
      margin: 0 10px 0 0;
    }

    & input[type="checkbox"], & input[type="radio"]{
      @include radioBtn
    }

    & .editChannel{
      display: flex;
      align-items: center;
      width: 50%;

      & input[type="text"]{
        margin-right: 10px;
        width: 100%;
        border: 1px solid gray;
        padding: 3px 5px;
        border-radius: 3px;
      }

      & button{
        border: 1px solid grey;
        border-radius: 3px;
        padding: 0;
        transition: all .3s ease;
        &:hover{
          background-color: #FF7C02;
        }
        & img{
          width: 30px;
        }
      }
    }
  }

  & .viewButton{
    @include viewButton
  }
}

.modal{
  width: 50%;
  max-height: 95vh;
  overflow-y: scroll;
  height: auto;

  @media (max-width: 768px) {
    width: 95%;
    max-height: 90%;
  }
}

.loader {
  @include loader;
}

.modal::-webkit-scrollbar {
  display: none;
}

.historyWidth {
  width: 70%;

  @media (max-width: 768px) {
    width: 95%;
  }
}

hr {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.view{
  margin-top: 20px;
  & > div{
    width: 100%;
  }

  & h3{
    text-align: center;
  }

  & h5{
    font-size: 16px;
  }
}

.player{
 width: 100%;
 height: auto;
 display: flex;
 flex-direction: column;
 justify-content: center;
 min-height: 200px;

  & div:first-of-type {
    max-width: 100%;
    max-height: 100%;
  }

 video {
  width: 30rem;
 }

 video::-webkit-media-controls-timeline {
    background-color: #f00;
    padding-bottom: 0;
    margin-bottom: 20px;
 }

 video::-webkit-media-controls-volume-slider {
    background-color: #f00;
    padding-top: 0;
    margin-top: 20px;
    padding-bottom: 0;
 }

 &__isHistoryView {
  video::-webkit-media-controls-timeline{
    display: none;
    background-color: #f00;
    padding-bottom: 0;
    margin-bottom: 20px;
  }
 }

 &__isLiveView{
   video::-webkit-media-controls-timeline{
     display: none;
     background-color: #f00;
     padding-bottom: 0;
     margin-bottom: 20px;
   }

   video::-webkit-media-controls-current-time-display{
     color: red;
   }

   video::-webkit-media-controls-play-button {
     background-color: transparent;
     border-radius: 50%;
     display: none;
   }

   video::-webkit-media-controls-mute-button {
     background-color: transparent;
     border-radius: 50%;
   }

   video::-webkit-media-controls-time-remaining-display {
     color: #119700;
     display: none;
   }

   video::-webkit-media-controls-fullscreen-button {
     background-color: transparent;
     border-radius: 50%;
   }
 }
}

.controls{
  display: flex;
  & buttons{
    margin-right: 10px;
    border: 1px solid gray;
  }
}
