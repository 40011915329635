body {
    overflow: hidden;
}

.btn-group-xs > .btn, .btn-xs {
  padding: .38rem .36rem;
  font-size: .875rem;
  line-height: .5;
  border-radius: .2rem;
}

$list: (
    ".BeaconContainer-enter",
    ".BeaconContainer-enter-active",
    ".BeaconContainer-enter-done",
    ".BeaconContainer-exit",
    ".BeaconContainer-exit-active",
    ".BeaconContainer-exit-done"
);

#beacon-container .Beacon .BeaconContainer:not(#{$list}) {
    display: none;
}

#beacon-container {
  @media print {
    display: none;
  }
}

/*#beacon-container .Beacon .BeaconContainer:not(
        .BeaconContainer-enter,
        .BeaconContainer-enter-active,
        .BeaconContainer-enter-done,
        .BeaconContainer-exit,
        .BeaconContainer-exit-active,
        .BeaconContainer-exit-done,
    ) {
    display: none;
}*/