@import "../../../../assets/styles/variables";

.VisibleToPeople {
  .VisibleToPeopleHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    background-color: $white;

    .VisibleToPeopleHeaderTitle {
      display: flex;
      gap: 4px;

      span {
        color: #111;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .VisibleToPeopleHeaderBtns {
      display: flex;
      gap: 10px;
    }
  }
}
