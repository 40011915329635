.non-user-alerts {
  display: flex;
  flex-direction: column;
  padding: 5px;

  .non-user-alerts__header {
    margin: 0;
    font-size: 14px;
  }

  .non-user-alerts__input {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .mas-input {
      border: none;
      background-color: #f7f7f7;
      width: 125px;
      flex: 0.55 0.55 0%;
      border-radius: 2px;
      font-size: 12px;
      padding: 10px;
      outline: none;
    }

    .btn {
      border: 1px solid black;
      flex: 0.3 0.3 0%;
      padding: 5px 10px;
      font-size: 12px;
      color: white;
      background-color: black;
      border-radius: 4px;
    }
  }

  .non-user-alerts__list {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding: 10px 5px 5px;
    background-color: rgb(247, 247, 247);

    p {
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 5px;
    }

    .non-user-alerts__list-item {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;

      .non-user-alerts__list-item__email {
        overflow: hidden;
        font-size: 13px;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0;
      }

      .non-user-alerts__list-item__icon {
        text-align: right;
        margin-left: 5px;
        margin-bottom: 0;
      }
    }
  }
}
