@import "./src/assets/styles/variables";

.itemized-block {
  &-wrapper {
    margin: 16px 20px 20px;

    &.mobile-layout {
      margin: 16px 10px 20px;
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    &-column {
      flex-direction: column;
      margin-bottom: 8px;
    }

    .mobile-layout & {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      align-items: initial; // ?
      justify-content: initial; // ?
    }
  }

  &-vehicle-name {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: -0.084px;
    word-break: break-word;
    color: $main_black;

    .mobile-layout & {
      font-size: 14px;
      line-height: 16px;
    }
  }

  &-vehicle-info {
    &-small {
      margin-top: 8px;
    }

    display: flex;

    .mobile-layout & {
      margin-top: 16px;
    }
  }

  &-vehicle-info-block {
    display: flex;
    align-items: baseline;
    margin-left: 24px;

    .mobile-layout & {
      margin-left: 16px;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  &-vehicle-info-label,
  &-vehicle-info-count {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;

    color: $main_black;

    .mobile-layout & {
      font-size: 12px;
    }
  }

  &-vehicle-info-label {}

  &-vehicle-info-count {
    font-weight: 600;
    margin-left: 8px;

    .mobile-layout &{
      margin-left: 4px;
    }
  }
}