@import '../../../assets/styles/variables';

.checkboxList {
  flex-shrink: 0;
}

.scrollBar {
  max-height: 318px;
  height: max-content;
}

.list {
  border: 1px solid $deviders;
  background: $main_white;
  padding: 11px; // 12 (on design) - 1 (border)
  display: grid;
  column-gap: 20px;
  row-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(118px, 1fr));

  &.notFound {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .notFoundMessage {
    margin: 0;
    color: $inactive;
    font-size: 12px;
  }

  .section {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .sectionItem {
      padding: 0;
      height: 16px;
      display: flex;
      align-items: center;
      column-gap: 8px;
      font-weight: 400;
      font-size: 11px;
      line-height: 12px;
      color: $inactive;

      .sectionItemCheckbox {
        width: 16px;
        height: 16px;
        color: $inactive2;
        flex-shrink: 0;
      }

      .sectionItemName {
        @include textOneLine;
      }

      &.checked {
        color: $main_black;

        .sectionItemCheckbox {
          color: $main_black;
        }
      }
    }
  }
}

.toggleSelectAllButtonWrapper {
  padding: 12px;
  background: $main_white;
  display: flex;
  flex-direction: column;
  border: 1px solid $dividers;

  .toggleSelectAllButton {
    background: $main_black;
    color: $main_white;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 4px;
    padding: 8px 22px;
    height: 28px;
    width: 100%;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    text-transform: capitalize;
    border-radius: 2px;

    .resetIcon {
      width: 12px;
      height: 12px;
      color: $main_white;
    }
  }
}

:global(.full-size) {
  .list {
    grid-template-columns: repeat(auto-fill, minmax(176px, 1fr));
  }

  .toggleSelectAllButton {
    width: max-content;
    align-self: flex-end;
  }
}
