@import '../../../assets/styles/variables';

.searchContainer {
  margin: 0;
  width: 100%;
  height: 32px;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  background: $white;
  border: 1px solid $deviders;

  .searchIcon {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
  }

  .searchInput {
    border: none;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $main_black;
    height: 20px;
    flex: 1;

    &::placeholder {
      color: $inactive;
    }
  }
}