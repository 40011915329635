.fencesContainer {
  max-height: calc(100% - 75px);
  display: flex;
  flex-direction: column;
}

.fencesContainerOpened {
  background-color: #fff;
}

.layersBtn {
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 220px;
  margin-left: 4px;
  margin-top: 4px;
  min-height: 32px;
  color: white;
  background-color: #111111;
  cursor: pointer;
  position: relative;
  & > .layersBtnText {
    margin-left: 5px;
    font-size: 11px;
    font-weight: 500;
  }
}