@import "src/assets/styles/variables";

.icon-tabs__container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .tabs {
    display: flex;
    gap: 6px;
    justify-content: space-around;
    overflow-x: auto;
    box-shadow: 3px 5px 12px $border_gray;
    padding-top: 6px;

    .tab {
      color: $inactive;
      cursor: pointer;
      transition: 0.2s;
      font-weight: 600;
      padding: 6px;
      border-radius: 5px 5px 0 0;

      &.active {
        color: $main_black;
        background: $border_gray;
      }
    }
  }

  .icons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .icon {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 90px;
      margin: 10px;

      img {
        width: 90px;
        padding: 8px 12px;
        border: 1px solid $border_gray;
        border-radius: 3px;
      }

      p {
        width: inherit;
        font-size: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        margin-top: 8px;
      }
    }

    .empty-icons-list {
      width: 100%;
      text-align: center;
      font-weight: 600;
      font-size: 2rem;
      margin: 12px 8px;
    }
  }
}