@import "../../../../assets/styles/variables.scss";

.field-set {
  position: relative;
  border: 1px solid #ECECEC;
  margin-top: 5px;
  padding: 15px;
  flex: 1 1 0;
  text-align: center;
  color: $inactive;
  font-size: 14px;
  color: black;
  font-weight: 400;

  &__header {
    color: $inactive;
    position: absolute;
    font-size: 12px;
    font-weight: lighter;
    top: 0;
    left: 0;
    transform: translate(20%, -50%);
    background-color: white;
    font-weight: lighter;
  }
}

.complete-task {
  width: 600px;
  background-color: $main_white;
  font-size: 12px;

  .mobile-layout & {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__header {
    padding: 20px;
    font-size: 16px;
  }

  &__names {
    &--grow {
      flex-grow: 1;
    }
  }

  &__headers {
    color: $inactive;
  }

  &__info {
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    align-items: center;
  }

  &__name {
    display: flex;
    width: 50%
  }

  &__data {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-right: -10px;
    margin-bottom: -10px;
    > .field-set {
      margin-right: 10px;
      margin-bottom: 10px;
    }
    width: 50%;
  }

  &__icons {
    width: 10px;
    fill: #878889;
    margin-right: 3px;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    box-shadow: 0px -2px 1px $deviders;

    .mobile-layout & {
      box-shadow: 0px -2px 1px $deviders;
    }
  }
}

.complete-task-form {
  padding: 0 20px;
  &__row {
    display: flex;
    justify-content: stretch;
    margin-bottom: 20px;
  }

  &__form-set {
    width: 50%;
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }

    &-full-width {
      width: 100%;
    }
  }

  // &__input {
  //     display: flex;
  //     &--date {
  //         width: 100%;
  //     }
  // }

  &__input-field {
    width: 100%;
    padding: 12px;
    color: black;
    font-size: 14px;
  }

  &__error {
    color: $denied;
    font-size: 14px;
    line-height: 14px;
    height: 10px;
  }

  &__error_special {
    color: $denied;
    font-size: 14px;
    line-height: 14px;
    height: 10px;
    margin-top: 15px;
  }
}