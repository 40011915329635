@import '../.././mixins.scss';

 .viewButton{
    @include viewButton
  }

 .channelsBlock{
    padding: 0;
    margin-top: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

.channelItem{
    display: flex;
    align-items: center;
    margin: 10px 0;

    & .nameChannel{
      display: flex;
      align-items: center;
    }

    & ul{
      padding: 0;
    }

    & h4{
      font-size: 18px;
      margin: 0 10px 0 0;
    }

    & input[type="checkbox"], & input[type="radio"]{
      @include radioBtn
    }

    & .editChannel{
      display: flex;
      align-items: center;
      width: 50%;

      & input[type="text"]{
        margin-right: 10px;
        width: 100%;
        border: 1px solid gray;
        padding: 3px 5px;
        border-radius: 3px;
      }

      & button{
        border: 1px solid grey;
        border-radius: 3px;
        padding: 0;
        transition: all .3s ease;
        &:hover{
          background-color: #FF7C02;
        }
        & img{
          width: 30px;
        }
      }
    }
}

.editChannel{
    display: flex;
    align-items: center;
    width: 50%;

    & input[type="text"]{
      margin-right: 10px;
      width: 100%;
      border: 1px solid gray;
      padding: 3px 5px;
      border-radius: 3px;
    }

    & button{
      border: 1px solid grey;
      border-radius: 3px;
      padding: 0;
      transition: all .3s ease;
      &:hover{
        background-color: #FF7C02;
      }
      & img{
        width: 30px;
      }
    }
  }

.nameChannel{
    display: flex;
    align-items: center;
  }