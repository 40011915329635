.billing-form {
  &__title {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 20px;
  }

  &__inputs {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0;

    &-input:nth-child(2) {
      margin-left: 30px;
    }

    &-input:focus {
      box-shadow: inset 0 1px 1px #00000014, 0 0 8px #66afe999;
      border-color: #66afe9;
      outline: 0;
    }

    &-input {
      flex: 1;
      gap: 10px;
      display: block;
      width: 100%;
      padding: 10px 30px 10px 10px;
      font-size: 14px;
      border: 1px solid #ccc;
      border-radius: 5px;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }
  }

  &__payment {
    display: flex;
    // justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }
}
