@import "./src/assets/styles/variables";

.task-details-modal {
  $this: &;
  background-color: $main_white;
  width: 280px;

  .mobile-layout & {
    width: 100%;
    position: absolute;
    bottom: 0;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }

  &-title {
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: $main_black;
  }

  &-close-button {
    &, &:focus {
      background: none;
      border: none;
      outline: none;
    }
    cursor: pointer;
    padding: 0;
    width: 16px;
    height: 16px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }

  &-close-icon {
    width: 10px;
    height: 10px;
  }

  &-top-block {
    padding: 8px 20px 20px;
  }

  &-bottom-block {
    //margin-top: 20px;
    padding: 20px;
    border-top: 1px solid $deviders;
  }

  &-info-item {
    display: flex;
    flex-direction: column;
    margin-top: 16px;

    &:first-child {
      margin: 0;
    }

    &-label {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 12px;
      color: $inactive;
      margin: 0;
    }
    &-text {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 12px;
      color: $main_black;
      margin: 4px 0 0;
    }
  }

  &-multi-items-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;

    #{$this}-info-item {
      margin: 0 16px 0 0;
      flex: 1;

      &:last-child {
        margin: 0;
      }
    }
  }

  &-info-documents-wrapper {
    max-height: 150px;
  }

  &-info-documents-block {
    margin-right: 10px;
  }
}