.container {
  display: flex;
  flex-wrap: wrap;

  .containerItem {
    min-width: 80px;
    display: flex;
    flex: 1;
    min-height: 35px;
    border: 1px solid #e5e5e5;
    align-items: stretch;
    justify-content: center;
    cursor: pointer;
  }
}

.inputBlock {
  borderTop: 1px solid #333;
  position: relative;
  background-color: #e5e5e5;
  display: flex;
  align-items: center;

  .searchIcon {
    color: #999;
    padding-bottom: 4px;
    padding-left: 4px;
    font-size: 12px;
  }

  .inputBar {
    width: 100%;
    font-size: 14px;
    background-color: transparent;
    outline: none;
    margin-left: 5px;
    padding: 5px;
    border: 1px solid #e5e5e5;
  }
}

.gridActions {
  display: flex;
  justify-content: flex-end;
  border: 1px solid #e5e5e5;
  padding: 3px 3px 3px 0px;

  .unitsSelected {
    color: #212529;
    display: flex;
    align-items: center;
    margin-right: 10px;
    font-size: 12px;
  }
}

.pagerContainer {
  margin-top: -1px;
  border: 1px solid #e5e5e5;
  padding: 0;
  display: flex;
  justify-content: space-between;

  .chevron {
    color: #2f76c3;
    cursor: pointer;
    padding: 10px;
  }

  .disabled {
    color: #999;
    cursor: not-allowed;
  }

  .pageIndicators {
    display: flex;
    align-items: center;
    max-width: 100%;
    overflow-x: auto;

    .pageIndicator {
      width: 12px;
      height: 12px;
      border-radius: 12px;
      min-width: 12px;
      border: 1px solid #999;
      margin: 0 5px;
      display: inline-block;
      cursor: pointer;
    }

    .currentPageIndicator {
      background-color: #2f76c3;
      border-color: #2f76c3;
      cursor: default;
    }
  }
}