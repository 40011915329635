.Drawer {
  $height: 100vh;
  position: absolute;
  transition: .3s linear;
  margin-top: 70px;

  &_anchor_top {
    top: - $height;
    left: 50%;
    transform: translate(-50%);

    &.open {
      top: 0;
    }
  }
}

.mobile-layout
.Drawer {
  width: 100%;
}
