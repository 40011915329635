@import "./src/assets/styles/variables";

.document-file-action-svg {
  &-delete{
    width: 15px;
    height: 15px;
  }

  &-cancel {
    width: 10px;
    height: 10px;
  }
}