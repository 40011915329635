.modal__cancellation {
  &-description {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    padding: 33px 25px;
    background-color: #f7f7f7;
    border-radius: 5px;
  }

  &-btn {
    margin-top: 30px;
  }
}
