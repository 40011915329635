@import "../../../assets/styles/variables";



.LoginPage {
  font-family: 'Roboto', sans-serif;

  display: flex;
  flex-direction: column;
  height: 100vh;
  max-width: 767px;
  margin: 0 auto;

  .title {
    font-size: 24px;
    color: #222;
    font-weight: 900;
    text-transform: uppercase;
  }

  .description {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 20px;
    font-weight: 900;
    text-align: center;
    color: var(--loginColor);
    text-transform: uppercase;
  }

  .singUpForm {
    display: block;
    height: 420px;
    overflow-y: scroll;
    @media(max-height: 735px){
      height: 350px;
    }
    .phone {
      width: 100%;
    }
    .selectBoxCountry {
      display: block;
      margin: 0 10px 0 10px;
      max-width: 97.5%;
    }
    .selectBoxState {
      display: block;
      max-width: 97.5%;
      min-height: 100%;
      margin: 0 10px 0 10px;
    }
    .doubleForm {
      display: flex;
      @media(max-width: 400px){
        display: block;
      }
    }
  }

  .loginBtns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .btn {
      margin-top: 10px;
      width: 100%;
	  border: 2px solid var(--loginColor);
	  background-color: var(--loginColor);
      color: $white;
      outline: none;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      padding: 10px 0;
      @media(max-width: 767px) {
        margin-bottom: 30px;
        width: 100%;
        margin-top: 12px;
      }
    }
    .cordova {
      width: 80%;
    }

    .loading {
      opacity: .5;
      pointer-events: none;
    }

    .fingerPrintBtn {
      width: 18%;
      padding: 8px 0;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .disabledFn {
      opacity: .5;
      pointer-events: none;
    }
  }
}
.LoginWrapper {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  font-style: normal;
  .Login {
	background-image: var(--loginBg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 100vh;
    width: 100%;
    .wrap {
      max-width: 1380px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100vh;
      @media (max-width: 1439px) {
        max-width: 1200px;
      }
      @media (max-width: 1279px) {
        max-width: 930px;
      }
      .logo {
        position: absolute;
        left: 25%;
        transform: translateX(-25%);
        @media (max-width: 1439px) {
          transform: unset;
          left: 60px;
        }
        @media (max-width: 1023px) {
          display: none;
        }
        svg, img {
          width: 365px;
        }
        @media (max-width: 1279px) {
          width: 200px;
        }
      }
      .log-box {
        z-index: 1;
        width: 800px;
        max-height: 946px;
        flex-shrink: 0;
        background-color: $main_white;
        border-radius: 10px;
        padding: 72px ;
        @media (max-width: 1023px) {
          padding: 52px;
          margin: 0 auto;
        }
        .title {
          font-style: normal;
          font-weight: 900;
          font-size: 48px;
          line-height: 48px;
          text-transform: uppercase;
          color: #222222;
          margin-bottom: 45px;
          @media (max-width: 1279px) {
            font-size: 40px;
            margin-bottom: 36px;
          }
        }
        .name {
		  color: var(--loginColor);
          font-style: normal;
          font-weight: 900;
          font-size: 20px;
          line-height: 28px;
          text-transform: uppercase;
          margin-bottom: 10px;
        }
        .description {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
          color: #222222;
        }
      }
      form {
        margin-top: 28px
      }
      .singUpForm {
        display: block;
        height: 470px;
        overflow-y: scroll;
        @media(max-width: 739px) {
          margin-top: 30px
        }

        .phone {
          width: 55%;
        }

        .selectBoxCountry {
          display: block;
          margin: 0 10px 0 10px;
          width: 52%;
        }

        .cityForm {
          display: flex;
        }

        .selectBoxState {
          display: block;
          width: 100%;
          min-height: 100%;
          margin: 0 10px 0 10px;
        }
      }
      .options {
        display: flex;
        justify-content: space-between;
        align-items: center;
        a {
          color: #F26F22;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .loginBtns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 45px;
        button {
          background-color: var(--loginColor);
          color: $main_white;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 14px;
          text-align: center;
          letter-spacing: 0.05px;
          text-transform: uppercase;
          padding: 18px 103px;
          border: 2px solid var(--loginColor);
          transition: all .3s ease-in;
          @media (max-width: 1279px) {
            padding: 18px 71px;
          }
          &:hover {
            background-color: transparent;
            color: var(--loginColor);
			border-color: var(--loginColor);
            cursor: pointer;
          }
        }
        .text {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 12px;
          color: #222222;
        }
        a {
          color: #F26F22;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

