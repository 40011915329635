.trip-travel-row {
    padding: 10px;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
	.mobile-layout &{
		padding: 6px 0;

		&:first-of-type{
			padding-top: 0;
		}

		&:last-of-type{
			padding-bottom: 0;
		}
	}
}

.trip-travel-row-value {
    font-weight: bold;
    text-align: end;
    div {
        justify-content: flex-end;
    }
}