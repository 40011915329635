@import "./src/assets/styles/variables";

.document-file-name {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  color: $main_black;
  overflow: hidden;

  display: flex;
  flex-direction: row;

  &-left {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-right {

  }
}