.peopleGridWrapper {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  flex-direction: row;
  align-content: space-between;
  gap: 12px;
  margin-bottom: 72px;

  @media (max-width: 756px) {
    justify-content: center;
    flex-wrap: wrap !important;
    flex-direction: initial !important;
  }
}

.peopleListWrapper {
  display: flex;
  gap: 12px;
  flex-wrap: nowrap;
  flex-direction: column;
  flex-flow: column;
  margin-bottom: 72px;
}

.peopleTypesSwitcher {
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 50px;

  margin-bottom: 12px;

  .typeSelector {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 50%;
    height: 100%;

    &:hover {
      cursor: pointer;
      font-weight: 800;
    }
  }

  .activeType {
    font-weight: 600;
  }
}

// .tableLink {
//   color: #fff;
// }

// .tableItem {
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: 'ellipsis';
//   max-width: 170px;

//   .VisibleDevicesTableHead {
//     display: flex;
//     justify-content: space-between;

//     .button {
//       margin: 10px 0;
//     }
//   }
// }

.timeInput {
  width: 100%;
}