.fenceAccordion {
  position: relative;
  &:first-child {
    padding: 24px 0 0;
  }
  &:not(&:first-child) {
    padding-top: 14px;
    &:after {
      content: '';
      display: block;
      width: 107%;
      height: 1px;
      border-bottom: 1px solid rgb(236, 236, 236);
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.tagDetails {
  height: 16px;
  font-size: 12px;
  padding-left: 16px;
  margin-bottom: 14px;
  color: #B9B9B9;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > svg {
    cursor: pointer;
  }
}

.tagsList {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-bottom: 24px;
  & > .tagsListLink {
    font-size: 11px;
    margin-top: 6px;
    text-decoration: underline;
    color: #111111;
    align-self: flex-start;
  }
}