.form__wrapper {
    padding-bottom: 100px;

    .button__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100%;
    }
}
