@import "../../../../assets/styles/variables.scss";

.ReportHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  margin-bottom: 12px;
  margin-top: 12px;

  .ReportHeaderDevicePaginate {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 24px;

    button {
      height: 24px;
      width: 24px;
      border: 1px solid $deviders;
      display: flex;
      justify-content: center;
      align-items: center;

      &:disabled {
        cursor: not-allowed;
      }
    }

    h3 {
      font-size: 14px;
      font-weight: 600;
      color: $main_black;
      margin: 0;
      height: 16px;
    }
  }

  .ReportHeaderBtns {
    display: flex;
    gap: 4px;
    align-items: center;
  }
}

.ReportTable {
  display: flex;
  flex-direction: column;
  width: 988px;
  border-radius: 8px;
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

  .ReportTableDateContainer {
    .ReportTableDate {
      background: $main_orange;
      padding-top: 8px;
      padding-bottom: 8px;
      height: 32px;
      display: flex;
      align-items: center;
      gap: 4px;

      button:disabled {
        cursor: not-allowed;
      }

      span {
        font-weight: 900;
        font-size: 12px;
        color: $main_white;
      }
    }
  }

  .ReportTableRow {
    display: grid;
    /// css fallback. same styles will set by JS
    grid-template-columns:
      minmax(64px, 1fr)
      minmax(142px, 1fr)
      minmax(64px, 1fr)
      minmax(72px, 1fr)
      minmax(64px, 1fr)
      minmax(72px, 1fr)
      minmax(64px, 1fr)
      minmax(150px, 1fr)
      minmax(150px, 1fr)
      minmax(150px, 1fr);
    align-items: center;
    border-bottom: 1px solid #ddd;

    &:hover {
      background-color: #f8f9fa;
    }

    .ReportTableCell {
      padding: 8px 12px;
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      a {
        color: #0066cc;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .ReportTableGridRow {
    display: grid;
    /// css fallback. same styles will set by JS
    grid-template-columns:
      minmax(64px, 1fr)
      minmax(142px, 1fr)
      minmax(64px, 1fr)
      minmax(72px, 1fr)
      minmax(64px, 1fr)
      minmax(72px, 1fr)
      minmax(64px, 1fr)
      minmax(150px, 1fr)
      minmax(150px, 1fr)
      minmax(150px, 1fr);
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    height: 64px;

    .ReportTableGridCell {
      height: 48px;
      padding-left: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 11px;
      font-weight: 500;
      color: $main_black;
      display: flex;
      align-items: center;
    }
  }

  .ReportTableHeader {
    background-color: #f7f7f7;
    padding-top: 8px;
    padding-bottom: 8px;
    height: 32px;
    border-bottom: 1px solid #ececec;
  }
}

.ReportTableCell-address {
  white-space: normal;
  line-height: 1.4;

  .city-line {
    color: #666;
    font-size: 12px;
  }
}

.ReportPagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  margin-top: 12px;
  margin-bottom: 62px;

  .ReportPaginationBtn {
    width: 120px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border: 1px solid $deviders;
    border-radius: 2px;
    padding: 6px 10px;
    color: $inactive;
    font-size: 11px;
    font-weight: 500;

    span {
      height: 13px;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .ReportPaginationPage {
    padding: 6px 10px;
    border-radius: 2px;
    background-color: $main_black;
    min-width: 24px;
    height: 24px;
    color: $main_white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
  }
}
