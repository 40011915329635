.total-cost{
  &-wrapper {
    &-small {
      margin-top: 24px;
      max-height: 251px;
    }

    margin-top: 32px;
    margin-left: 20px;
    margin-right: 20px;
    max-height: 243px;

    .mobile-layout & {
      margin-top: 32px;
      margin-left: 10px;
      margin-right: 10px;
      max-height: 240px;
    }
  }

  &-scroll {
    max-height: inherit;
    padding-right: 10px;
  }
}