@import "../../../../assets/styles/variables";

.report-filter-wraper {
	background-color: $main_white;
	position: relative;

	.report-filters-actions {
		border-top: 1px solid $dividers;

		display: flex;
		justify-content: space-between;

		padding: 15px;
		background-color: $main_white;

		.mobile-layout & {
			padding: 16px 20px 32px;
			&>.w100 {
				display: flex;
				width: 100%;
			}
		}

		.apply-btn {
			margin: 5px;
		}
	}

	.mobile-layout & {
		height: 100%;
		position: relative;
		display: flex;
		flex-direction: column;

		.scrollbar-container {
			height: fit-content;
			flex-grow: 1;
		}

		hr {
			width: 100%;
			margin: 0;
			border-top: 1px solid $dividers;
		}
	}
}

.report-filters {
	width: 500px;
	max-height: 600px;
	padding: 20px;
	background-color: $main_white;
	position: relative;

	.mobile-layout & {
		width: auto;
		padding: 0;
		max-height: 600px;

		&:nth-of-type(2) {
			padding: 32px 0 20px;
		}
	}

	.report-filters-header {
		margin-bottom: 20px;

		.mobile-layout & {
			margin-top: 26px;
			svg {
				position: absolute;
				right: 0;
				top: 21px;
				height: 24px;
				width: 24px;
				cursor: pointer;

				path {
					fill: black;
				}
			}
		}

		h3 {
			font-weight: 600;
			font-size: 16px;
			line-height: 16px;

			.mobile-layout & {
				text-align: center;
				font-size: 14px;
				line-height: 12px;
				margin: 0;
			}
		}
	}

	.report-filter-section {
		background-color: $main_gray;
		margin-bottom: 8px;
		border-radius: 4px 4px 0 0;

		.report-filter-labels-header {
			display: flex;
			flex-grow: 1;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;
			overflow: hidden;
		}

		.report-posted-speed {
			display: flex;
			justify-content: space-between;
			align-items: center;

			input {
				appearance: none;
				width: 20px;
				height: 20px;
				border: 2px solid #ff7c02;
				border-radius: 4px;
				cursor: pointer;
				display: inline-block;
				position: relative;
				background-color: white;
			}

			input:checked {
				background-color: #ff7c02;
				border-color: #ff7c02;
			}

			input:checked::before {
				content: "";
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: white;
				mask-image: url("../../../../assets/svg/check_white.svg");
				-webkit-mask-image: url("../../../../assets/svg/check_white.svg");
				mask-repeat: no-repeat;
				mask-position: center;
				mask-size: contain;
			}
		}

		.report-filter-icon {
			margin-right: 20px;
			width: 20px;

			.mobile-layout & {
				margin-right: 6px;
				width: 16px;
			}
		}
	}
}

.date-choser-filter-section {
	background-color: #F8F8F8;
}

.date-filter-tabs {
	border-bottom: 1px solid $secondary_gray;
	display: flex;
	justify-content: center;

	.date-tab {
		padding: 9px 16px;
		font-weight: bold;
		color: $inactive;
		cursor: pointer;

		&.active {
			color: $main_black;
			border-bottom: 2px solid #FF7C02;
		}
	}
}

.report-filter {
	.report-filter-header {
		padding: 20px;
		border-top: 5px;

		color: $inactive;
		font-weight: bold;
		fill: $inactive;

		&.active {
			color: $main_black;
			fill: $main_black;
		}

		.mobile-layout & {
			padding: 0;
		}
	}

	.report-filter-body {
		border-top: 1px solid #ECECEC;
		padding: 20px;

		.mobile-layout & {
			padding: 0;
		}
	}
}