@import "./src/assets/styles/variables";

.devices-table {
  margin: 0 0 20px;
  border: 1px solid $dividers;

  .mobile-layout & {
    margin: 0;
  }

  &-head {
    background: $bg_input;
    border: 1px solid $dividers;

    &-row {
      &-itm {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: $main_black;

        .mobile-layout & {
          white-space: nowrap;
        }
      }
    }
  }

  &-body {
    background: $main_white;

    &-row {
      &-itm {
        font-family: 'Roboto', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 12px;
        color: $main_black;
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }

    .deviceName {
      &-small {
        min-width: 92px;
      }

      word-break: break-word;
      min-width: 190px;

      .mobile-layout & {
        min-width: 92px;
      }
    }
  }

  &-wrapper {
    &-small {
      margin-top: 32px;
    }

    margin: 24px 20px 0;

    .mobile-layout & {
      margin: 32px 10px 0;
    }
  }

  &__pagination {
    font-size: 12px;
  }
}