@import '../../../../assets/styles/variables';

.selectedTagItem {
  display: flex;
  align-items: center;
  column-gap: 4px;
  height: 20px;
  padding: 3px 8px;
  background: $bg_tag_label;
  border-radius: 10px;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.25px;
  color: $main_black;

  &.withClose {
    padding-right: 4px;
  }

  .selectedTagName {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .selectedTagItemIcon {
    width: 12px;
    height: 12px;
  }
}