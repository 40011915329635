.DeviceHeader {
  display: flex;
  gap: 12px;
  border: 1px solid #ddd;
  padding: 12px;
  background: #fff;
  width: 100%;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  .DeviceHeaderImage {
    flex: 6;
    aspect-ratio: 16 / 9;
    //min-width: 150px;
    max-width: 132px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }

  .DeviceHeaderInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 4;

    h3 {
      text-align: center;
    }

    button {
      width: 100%;
      padding: 4px;
      border: 1px solid #878889;
      border-radius: 4px;

      &:focus,
      &:active {
        outline: 0;
        border: 1px solid #878889 !important;
      }

      &:hover {
        border: 1px solid #111;
      }
    }

    .DeviceHeaderInfoLocation {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .DeviceHeaderLocationBtn {
        display: flex;
        gap: 4px;
        align-items: center;
        font-size: 11px;
        line-height: 14px;

        img {
          height: 14px;
        }
      }

      .DeviceFormattedAddress {
        display: flex;
        flex-direction: column;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
      }
    }

    .DeviceHeaderViewOnMap {
      font-size: 11px;
      line-height: 12px;
      letter-spacing: 0.25px;
      text-align: center;
      max-width: 120px;
      margin-top: 10px;
    }
  }
}