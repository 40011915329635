@import "../../../../assets/styles/variables";
@import "../../fences.mixins";

.TittleBox {
  position: sticky;
  z-index: 10;
  top: 0;

  height: 38px;
  border-bottom: 1px solid #ECECEC;
  display: flex;
  align-items:center;
  justify-content: space-between;
  padding: 14px 12px 7px;
  background-color: $main_white;

  &-Actions {
    display: flex;
    align-items: flex-start;
  }

  span {
    font-size: 11px;
    font-weight: 500;
  }

  &-Button-Icon {
    display: flex;
    align-items: flex-start;
    height: 25px;
    .trash-fill {
    fill: $inactive;
    }
  }

  &-Icon {
    width: getVw(16);
  }
}

.EditForm {
  display: flex;
  flex-direction: column;
  .ButtonsBlock {
    @include fenceButtonsBlock(12px 0 12px 16px);
    &>button {
      cursor: pointer;
    }
    .WhiteButton {
      @include fenceButton(white,black,94px);
    }
    .BlackButton {
      @include fenceButton(black, white, 94px);
    }
  }
}

.ExtraInfo-label {
	display: flex;
	justify-content: space-between;

	svg {
		cursor: pointer;
		width: 16px;
		height: 16px;
		opacity: 0.4;
	}
}

.IconBox {
  display: flex;
  margin-bottom: 7px;
}

.Icon {
  height: 40px;
  width: 40px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    height: 24px;
    width: 24px;
  }
}

.ColorPicker {
  & > div {
    padding: 0!important;
    box-shadow: none!important;
    border: none!important;
    margin-top: -2px;
    margin-left: 11px;

    span {
      margin: 2px 3px;

      div {
        width: 18px!important;
        height: 18px!important;
        border-radius: 2px!important;
      }
    }
  }

  div {
    padding: 0;
  }
}

.SubTitle {
  font-size: 11px;
  font-weight: 600;
}

.LightTitle {
  font-size: 11px;
  font-weight: normal;
  margin-bottom: 8px;
}

.SvgElement {
  cursor: pointer;
}
.ContentBox {
  display: flex;
  flex-direction: column;
  padding: 24px 0 20px 16px;
  &:not(:last-child) {
    border-bottom: 1px solid #ECECEC;
  }
}

.LabelInput {
  border: none;
  background-color: #F7F7F7;
  border-radius: 2px;
  font-size: 12px;
  padding: 12px;
  margin-bottom: 20px;
}

.DescriptionArea {
  border: none;
  background-color: #F7F7F7;
  border-radius: 2px;
  font-size: 12px;
  opacity: 0.5;
  padding: 12px;
  margin-bottom: 20px;
  resize: none;
}

.FenceTypeBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
	&>div button {
		cursor: pointer;
	}
}

.CoordinatesBox {
  margin-top: 8px;
  font-size: 11px;
  font-weight: normal;

  span:first-child {
    margin-right: 12px;
  }
}

.RecenterButton {
  font-size: 11px;
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;
  color: black;
}

.RecenterBox {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.AlertBlock {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  align-items: center;

  div {
    display: flex;
    align-items: center;

    span {
      text-decoration: underline;
      margin: 0;
    }

    svg {
      margin-bottom: 8px;
      margin-left: 4px;
      cursor: pointer;
      width: 14px;
      height: 14px;
    }
  }
}

.ExtraInfo {
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: 2px;
  background-color: #F6F6F6;
  padding: 10px;
  margin-top: 8px;

  &>p {
  	text-align: center;
  }

  & p,
  label {
  	font-size: 13px;
  	line-height: 20px;
  }

  div {
    display: flex;
    justify-content: space-between;
	 align-items: center;
    margin-bottom: 8px;

    span {
      font-size: 11px;
      font-weight: 600;
      height: 12px;
    }

    svg {
      cursor: pointer;
      width: 16px;
      height: 16px;
      margin-left: 4px;
    }
  }

  input {
    border: none;
    background-color: #fff;
    border-radius: 2px;
    font-size: 12px;
    height: 22px;
    padding: 1px 4px;
	 width: 50%;
  }
}

.WaypointBox {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  align-items: center;
}

.SettingsList {
  padding: 0;
  margin: 0;
  list-style: none;

  &-Group {
    display: flex;
    justify-content: space-between;

    margin-top: 20px;
  }

  &-Label {
    font-size: 11px;
    font-weight: normal;
    margin-bottom: 8px;
    &--Link {
      text-decoration: underline;
    }
  }

  &-SettingsIcon {
    margin-left: 2px;
    width: 12px;
    height: 12px;
  }
}
