.container {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.label {
    font-size: 15px;
    padding-bottom: 10px;
    margin: 0 auto;

}

.button {
    text-decoration: underline;
}

.buttonRed {
    color: darkred;
}

.buttonOrange {
    color: orange;
}

.buttons {
    display: flex;
    margin: 0 auto;
}

.hint {
    font-size: 11px;
    width: 200px;
    text-align: center;
    margin: 0 auto;
    color: darkorange;
    padding-bottom: 10px;
}
