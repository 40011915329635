@import "../../../../assets/styles/variables";
@import "../../fences.mixins";

.FenceBody {
  .ButtonsBlock {
      @include fenceButtonsBlock(12px 0 0 16px);
      &>button {
        cursor: pointer;
        outline: none;
      }
      .WhiteButton {
        @include fenceButton(white,black,94px);
      }
      .BlackButton {
        @include fenceButton(black, white, 94px);
      }
  }
  .ButtonsBlockCentered {
    justify-content: center;
  }
  &-SimpleLink {
    font-size: 11px;
    text-decoration: underline;
    color: #111111;
  }
  &-Mobile {
    @include fenceMobile;
  }

  &-Content{
    display: flex;
    flex-direction: column;
    padding: 24px 0 0 16px;
  }

  &-List {
    display: flex;
    flex-direction: column;
  }
  &-MobileNotify {
    flex: 1;
    background-color: white;
    height: 100%;
    padding: 40px;
  }
}

.FencesBox {
  display: flex;
  align-items: center;
  margin: 6px 0;
  //height: 16px;

  span {
    line-height: 12px;
    opacity: 0.6;

    &:hover {
      opacity: 1;
      text-decoration: underline;
    }
  }

  svg {
    width: getVw(16);
    opacity: 0.4;

    &:hover {
      opacity: 1;

    }
  }
}

.SearchInput {
  padding: 4px;
  font-size: 12px;
  margin-bottom: 6px;
  outline: none;
  border: 1px solid $inactive2;
}

