@import "./src/assets/styles/variables";

.uploaded{
  &-documents-wrapper {
    max-height: 280px;
    overflow-y: auto;
  }

  &-form {
    margin-top: 10px;

    &__error {
      margin: 8px 0;
      font-size: 10px;
      color: $denied;
    }

    &__controls {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }
}