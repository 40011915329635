@import "../../assets/styles/variables";

.longReportModal {
  background-color: #FFFFFF;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .buttons {
    display: flex;
    gap: 15px;

    button {
      color: #333;
      background-color: $main_orange;
      padding: 5px 20px;
      border-radius: 10px;
      font-weight: 500;
    }

    button:hover {
      color: #fff;
      font-weight: 700;
    }
  }
}