@import "./src/assets/styles/variables";

.document-file {
  &, &:focus {
    border: none;
    outline: none;
    text-align: left;
    //width: 100%;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  margin-top: 8px;
  background: #F4F4F4;
  border-radius: 2px;
  overflow: hidden;

  //&:first-child {
  //  margin-top: 0;
  //}

  &-pointer {
    cursor: pointer;
  }

  &-icon-block {
    width: 26px;
    height: 26px;
    margin-right: 8px;
    &-inload {
      position: relative;
      &:before {
        position: absolute;
        width: 100%;
        height: 100%;
        background: $main_orange;
        content: '';
      }

    }
  }

  &-info {
    flex: 1;
    overflow: hidden;
    margin-right: 10px;
  }

  &-size {
    //font-family: Sofia Pro;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 12px;
    color: $inactive;
    letter-spacing: -0.078px;
    margin-top: 2px;
  }

  &-cancel-text {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 9px;
    line-height: 12px;
    color: $denied;
    letter-spacing: -0.078px;
    margin-top: 2px;
  }

  &-action {
    &-button {
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &, &:focus {
        background: none;
        border: none;
        outline: none;
      }
    }
  }
}