.dnd {
  display: flex;
  flex-direction: column;

  .dnd__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    outline: none;

    span {
      margin: 0;
      font-size: 14px;
      padding: 5px;
    }
  }

  .dnd__text {
    margin: 0;
    font-size: 12px;
    padding: 5px;
  }

  .dnd__days {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 5px;

    .btn {
      flex: 1;
      border-radius: 0;
      border-color: rgb(135, 136, 137);
      width: 20px;
      padding: 5px;

      span {
        font-size: 14px;
        margin: auto;
        padding: 0;
      }
    }
  }
}
