@import '../../../assets/styles/variables';

.modal{
    width: 43%;
    max-width: 432px;
    max-height: 95vh;
    height: auto;
  
    @media (max-width: 1265px) {
      width: 53%;
      max-height: 90%;
    }
  
    @media (max-width: 1100px) {
      width: 63%;
      max-height: 90%;
    }
  
    @media (max-width: 840px) {
      width: 90%;
      max-height: 90%;
    }
  }

.modalContainer {
  background-color: $main_white;
  padding: 20px 20px 15px;
  border-radius: 4px;
}

.title {
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: $main_black;
  margin: 0 0 20px;
}

.switcher {
  margin: 16px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
  $topBottomPadding: calc(12px - 9px); // 12px - as on design, 9px - padding on switcher
  padding: #{$topBottomPadding} 12px;
  border: 1px solid $dividers;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  text-transform: capitalize;
  cursor: pointer;
}

.copyLinkSection {
  margin-top: 16px;
  display: flex;
  column-gap: 8px;

  .publicLink {
    flex: 1;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-decoration: underline;
    text-decoration-style: solid;
    color: $main_black;
    padding: 6px 12px;
    background: $bg_input;
    border-radius: 2px;
    @include textOneLine;
  }

  .copyButton {
    flex-shrink: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 4px;
    color: $inactive;
    padding: 8px 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-transform: capitalize;
    background: $dividers;
    min-width: 110px;

    .copyIcon {
      flex-shrink: 0;
      width: 12px;
      height: 12px;
    }
  }
}

.footer {
  margin-top: 20px;
  padding: 16px 0 0;
  border-top: 1px solid $dividers;
  display: flex;

  .closeButton {
    margin-left: auto;
    padding: 8px 12px;
    font-weight: 500;
    font-size: 11px;
    line-height: 12px;
    text-align: center;
    text-transform: capitalize;
    border-radius: 2px;
    border: 1px solid $inactive2;
    color: $inactive;
  }
}