.editPathTop {
  display: flex;
  justify-content: space-around;
  font-size: 18px;
  text-align: center;
}

.fenceClear, .fenceRecord {
  color: #424250;
  text-decoration: none;
  display: flex;
  flex-direction: column;
}

.fenceRecord {
  color: initial;
  & > .fenceRecordRed {
    color: red;
  }
}

.fenceFind {
  font-size: smaller;
  text-decoration: none;
  text-align: center;
}

.fenceTitle {
  font-weight: bold;
  margin-right: 10px;
}

.editRow {
  padding-bottom: 8px;
  display: flex;
  & > .editRowWrap {
    flex-wrap: wrap;
  }
}

.inputCSS {
  // inputCSS
  border: 1px solid #2f76c3;
  padding: 2px;
  outline: none;
  // end
}

.inputFullWidth {
  width: 100%;
}

.inputFlex {
  flex: 1;
}
