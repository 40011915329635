@import "./src/assets/styles/variables";

.avg-vs-cost {
  &-wrapper {
    &-small {
      margin-top: 24px;
    }
    margin-top: 32px;
    position: relative;

    .recharts-brush {
		 height: 15px;
		 opacity: 0.6;
      margin-top: 16px;
		transition: all .2s linear;

		&:hover {
			opacity: 0.9;

			&>rect {
				height: 15px;
			}

			.recharts-brush-slide {
				height: 11px;
				fill: #999;
			}
		}

		&>rect {
			stroke: none;
			display: inline-block;
		}

      &-slide {
			fill: #aaa;
			height: 6px;
			rx: 6;
			ry: 6;
			//y: 282px;
        fill-opacity: 1;
        cursor: grab !important;
		  transition: all .2s linear;
      }

      &-traveller {
			display: none;
        pointer-events: none;
        & rect, & line {
          fill: $main_black;
          stroke: $main_black;
        }
      }

      &-texts {
        display: none;
      }
    }
  }

  &-custom-tooltip {
    background-color: $main_black;
    color: $main_white;
    padding: 8px;
    min-width: 88px;
    box-sizing: border-box;

    &-text {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 9px;
      line-height: 9px;
      letter-spacing: 0.01em;
      color: $main_white;
      margin-top: 4px;
    }

    &-name {
      font-size: 10px;
      font-weight: bold;
      padding-bottom: 4px;
      margin-bottom: 4px;
      margin-top: 0;
      border-bottom: 0.5px solid $inactive;
    }
  }

  &-legend-item-text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 10px;

    letter-spacing: 0.01em;
    color: $main_black;

    .mobile-layout & {
      font-size: 12px;
      line-height: 12px;
    }
  }

  &-y-custom-tick {
    background: $main_black;
    color: $main_white;
    padding: 5px;
    font-size: 11px;
  }
}

