.modalContent {
  position: relative;
  overflow-y: scroll;
  max-height: 90vh;
  padding: 20px;
  border-radius: 12px;
  background-color: white;
  width: 60vw;

  @media (max-width: 1060px) {
    max-height: 90vh;
    width: 80vw;
  }
}

.modalContent::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.modalHeader {
  &Row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;

    button {
      height: 40px;
      width: 40px;
      flex-shrink: 0;
      background: #4d4646;
      border-radius: 10px;
      color: white;

      &:hover {
        background: grey;
        transition: ease-in 0.3s;
      }
    }
  }

  h2 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
  }
}

.buttons_block {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.asking_text {
  font-size: 15px;
}

.modal_size {
  width: 35vw;
  padding: 30px 50px;
}

.prompt_text {
  flex-direction: column;
  padding: 10px 0;
}
