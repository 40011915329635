.form {
  &__icon {
    width: 12px;
    color: var(--neutral-icon-color);
  }

  input[type='file'] {
    display: none;
  }

  // it is overriden globaly to margin-bottom: 0.5rem;
  label {
    margin-bottom: 0;
    cursor: pointer;
  }

  button {
    padding: 0;
  }
}