@mixin viewButton {
  background-color: #ff7c02;
  color: #ffff;
  fill: #ffff;
  transition: 0.3s ease;
  width: 100px;
  margin: 10px auto 0 auto;
  font-size: 18px;
  border-radius: 3px;
  border: 1px solid #ff7c02;
  cursor: pointer;
  &:hover {
    border: 1px solid #ff7c02;
    background-color: transparent;
    color: gray;
  }
  &:focus{
    border: 1px solid #ff7c02!important;
  }
}

@mixin radioBtn {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  accent-color: green;
}

@mixin loader {
  position: absolute;
  top: 50%;
  left: 50%;
}
