.gm-style-iw {
    padding: 0 !important;
}

.gm-style-iw-c {
    padding: 0 !important;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.2) !important;
}

.gm-style-iw-d {
    overflow: hidden !important;
    padding: 0 !important;
    max-height: none !important;
}

.gm-ui-hover-effect {
    display: none !important;
}

.custom-info-window {
    position: relative;
    padding: 0 12px 12px 12px;
    margin: 0;
    width: 100%;
}

.custom-close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #ddd;
    color: #555;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 10;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.2s ease;
}

.custom-close-button:hover {
    background-color: #f5f5f5;
    color: #333;
}

.info-content {
    width: 100%;
    padding: 0;
}

.info-content > div {
    padding: 0;
    margin: 0;
}
