@import "./variables.scss";

.h-8 {
    height: 8px;
}

.mv-8 {
    margin: 0 8px;
}

.status-check-circle {
    fill: $main_orange;
}

.status-circle {
    fill: $deviders;
}

.icon-16px {
    width: 16px;
    height: 16px;
}