@import '../../assets/styles/variables.scss';

.deviceOrderItem {
  border: 1px solid $mediumGray;
  padding: 4px 10px;
  cursor: grab;
  user-select: none;
  width: 200px;
}

:global(.dragged) {
  .deviceOrderItem {
    cursor: grabbing;
  }
}