.modal__header {
  &-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    & button {
      height: 40px;
      width: 40px;
      background: #4d4646;
      border-radius: 10px;
      color: white;
    }

    & button:hover {
      background: grey;
      transition: ease-in 0.3s;
    }
  }

  & h2 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
  }

  & p {
    font-weight: 400;
  }
}

.modal__device {
  margin: 10px 0;

  p {
    margin-bottom: 0;
  }

  &-title {
    text-transform: uppercase;
    color: #f26f22;
    font-size: 20px;
    line-height: 28px;
    font-weight: 800;
  }

  &-description {
    background-color: #f7f7f7;
    border-radius: 4px;
    color: #222;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.15px;
    padding: 33px 25px;
    margin-top: 10px;

    ul {
      margin: 5px 0;
    }
  }
}
