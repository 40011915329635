@import '../../../assets/styles/variables';

@mixin labelText {
  font-weight: 300;
  font-size: 11px;
  line-height: 12px;
  color: $main_black;
}

@mixin section {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  p {
    margin: 0;
  }
}

.sectionLabel {
  @include labelText;
}

.deviceIcon {
    width: 40px;
    padding: 5px;
    cursor: pointer;

    & > img {
      width: 100%;
    }

    & > p {
      font-size: 10px;
      text-align: center;
    }
}

.gridCameras {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320.5px, 1fr));
  justify-items: center;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}

.title {
  font-size: 10px;
  text-align: center;
  margin: -7px;
  color: #212529;
}

.tagNameSection {
  .tagNameSectionLabel {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      margin: 0;
    }

    .saveChangesButton {
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      color: $main_orange;
      display: none;
    }
  }

  .tagNameInput {
    margin-top: 8px;
    padding: 12px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    background: $main_white;

    input {
      flex: 1;
      border: none;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0;
    }

    .closeButton {
      padding: 0;
      width: 16px;
      height: 16px;
      flex-shrink: 0;
      display: none;

      .closeIcon {
        width: 16px;
        height: 16px;
      }
    }
  }

  &.editMode {
    .saveChangesButton {
      display: block;
    }

    .tagNameInput {
      border: 1px solid $main_orange;

      .closeButton {
        display: block;
      }
    }
  }
}


.tagIconSection {
  @include section;

  .tagIconContent {
    display: flex;
    column-gap: 14px;

    .tagIcon {
      width: 98px;
      height: 98px;
      background: $main_white;
      border: 1px solid $deviders;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }

    .colorsPalette {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      align-items: flex-start;
      align-content: flex-start;
      max-width: 220px;

      .colorItem {
        width: 18px;
        height: 18px;
        padding: 0;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;

        .colorItemIcon {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}

.actionsSection {
  @include section;

  .actionsList {
    display: flex;
    column-gap: 8px;

    .actionItem {
      flex: 1;
      max-width: 88px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 8px;
      padding: 8px;
      color: $inactive;
      font-weight: 400;
      font-size: 10px;
      line-height: 100%;
      background: $main_white;
      border-radius: 4px;

      &:disabled {
        cursor: not-allowed;
      }

      &.actionOn {
        color: $main_black;

        .actionItemIcon {
          color: $main_orange;
        }
      }

      .actionItemIcon {
        color: $main_black;
        width: 16px;
        height: 16px;
      }
    }
  }
}

.selectedDeviceContainer {
  margin-top: 16px;
  @include labelText;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;

  p {
    margin: 0;
  }

  .selectedCount {
    font-weight: 500;
  }
}

.searchInput {
  margin-top: 12px;
}

.checkboxesList {
  margin-top: 8px;
}