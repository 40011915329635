@import "../../assets/styles/variables";


.icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 62px;
  min-width: 55px;

  .icon-name {
    font-size: 9px;
    letter-spacing: 0.2px;
    text-align: center;
    color: rgb(255, 245, 245);
  }

  label {
    margin-bottom: 0;
  }
}
.ToggleButton {
  box-sizing: border-box;
  align-self: center;
  display: flex;

  margin-bottom: 2px;

  padding: getVw(8) getVw(10);
  background-color: $main_black;
  border: 1px solid $border-gray;
  border-radius: getVw(4);
  color: rgba($main_white, .4);

  font-style: normal;
  font-weight: normal;
  font-size: getVw(11);
  line-height: getVw(12);

  transition: all .3s ease-in-out;
  &:focus {
    outline: none;
  }

  > svg {
    width: getVw(12);
    fill: $main_white;
    fill-opacity: 0.4;
    transition: all .3s ease-in-out;
    margin-right: calc(5 / var(--base-container) * 100vw);
  }

  &:not([disabled]):hover {
    cursor: pointer;
    color: $main_white;

    > svg {
      fill: $main_white;
      fill-opacity: 1;
    }

  }

  &.ToggleButton_pressed {
    color: $main_orange;
    border: 1px solid $main_orange;
    svg {
      fill: $main_orange;
      fill-opacity: 1;
    }
  }
}
.mobile-layout
.ToggleButton {
  padding: 0;
  max-height: 28px;
  min-height: 28px;
  width: 28px;

  > svg {
    width: 12px;
    margin: auto;
  }
}
