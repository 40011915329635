.activation-info {
  margin-top: 30px;

  &__block {
    display: flex;
    align-items: center;
    gap: 20px;

    img {object-fit: contain;}
  }
  
  &__description {
    
    h5 {
      font-size: 16px;
      font-weight: bold;
    }

    p {
      font-size: 16px;
    }
    
  }
}