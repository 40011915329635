@import "./src/assets/styles/variables";

.total-cost-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &-small {
    margin-top: 16px;
  }

  margin-top: 24px;

  .mobile-layout & {
    margin-top: 20px;
  }

  &:first-child {
    margin-top: 0;
  }

  &__name {
    &-small {
      width: 86px;
      font-size: 10px;
      line-height: 10px;
    }


    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: -0.084px;
    color: $inactive;
    word-break: break-word;
    width: 124px;

    .mobile-layout & {
      font-size: 12px;
      line-height: 10px;
      width: 104px;
    }
  }

  &__percent {
    &-small {
      margin: 0 8px;
    }

    margin: 0 16px;
    flex: 1;
    background-color: $secondary_gray;
    border-radius: 5px;

    height: 5px;
    position: relative;

    .mobile-layout & {
      margin: 0 8px;
    }

    &-color {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-radius: 3px;
      background-color: $main_orange;
    }
  }

  &__price {
    &-small {
      width: 64px;
      font-size: 8px;
      line-height: 10px;
    }

    width: 86px;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 11px;
    letter-spacing: -0.084px;
    word-break: break-word;
    color: $inactive;

    .mobile-layout & {
      width: 88px;
      font-size: 12px;
      line-height: 10px;
    }
  }
}