@import "../variables";


.Tabs_theme_dark {
  .Tabs-Tab {
    background-color: $theme-dark-tab-bg-color;
    color: /*$theme-light-text-color*/ $theme-dark-text-color;
    box-shadow: getVw(18) getVw(19) getVw(31) rgba($theme-dark-text-color, 0.1);

    &_active {
      background-color: $theme-dark-tab-active-bg-color;
    }
  }

  & + .Tabs-TabContent {
    background-color: $theme-dark-tab-active-bg-color;
  }
}

.Tabs.Tabs_styled_trapeze.Tabs_theme_dark {
  .Tabs-Tab {
    &:not(:first-child)::after {
      border-left: getVw($tab-horizon-border-units) solid $theme-dark-tab-bg-color;
    }

    &:not(:last-child)::before {
      border-right: getVw($tab-horizon-border-units) solid $theme-dark-tab-bg-color;
    }

    &_active {
      color: $theme-dark-active-text-color;

      &:not(:first-child)::after {
        border-left-color: $theme-dark-tab-active-bg-color;
      }

      &:not(:last-child)::before {
        border-right-color: $theme-dark-tab-active-bg-color;
      }
    }
  }
}
