.PointDetails {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  padding: 0px 12px 12px 12px;
  gap: 12px;
  max-width: 768px;

  .PointDetailsHeaderCointainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .PointDetailsHeader {
      display: flex;
      padding-right: 12px;
      gap: 10px;
      align-items: center;
      max-width: 90%;

      .PointDetailsImage {
        height: 40px;
      }

      .PointDetailsData {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .PointDetailsInfoContainer {
          display: flex;
          gap: 8px;
          align-items: center;

          .PointDetailsBtnContainer {
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #ececec;
            cursor: pointer;
          }

          .PointDetailsName {
            font-size: 12px;
            font-weight: 500;
            color: #111;
          }
        }

        .PointDetailsMessage {
          display: flex;
          align-items: center;
          gap: 2px;
          height: 14px;
          font-size: 10px;
          font-weight: 300;
          color: #111;
        }
      }
    }

    .PointDetailsPinnedContainer {
      display: flex;
      align-items: center;
      gap: 2px;
      padding-right: 20px;

      span {
        font-size: 10px;
        font-weight: 400;
        color: #878889;
      }
    }
  }

  .PointDetailsBorder {
    display: flex;
    flex: 1;
    border-bottom: 1px solid #ececec;
  }

  .PointDetailsStatusBar {
    min-height: 32px;
    border-radius: 4px;
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 11px;
      font-weight: 400;
    }
  }

  .PointDetailsInfo {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .InfoEntry {
      display: flex;
      gap: 4px;
      color: #111;
      font-size: 10px;
      //flex-wrap: wrap;

      .Label {
        font-weight: 300;
      }

      .InfoData {
        font-weight: 500;
      }
    }
  }

  .PointDetailsBtns {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    .PointDetailsButton {
      display: flex;
      gap: 4px;
      padding: 4px 12px;
      border-radius: 8px;
      border: 1px solid #ececec;
      height: 24px;
      cursor: pointer;

      &:focus {
        border: 1px solid #ececec !important;
        outline: none !important;
      }

      &:hover {
        border: 1px solid #111;
      }

      span {
        font-size: 11px;
        font-weight: 400;
        color: #111;
      }
    }

    .PointDetailsRedBtn {
      display: flex;
      gap: 4px;
      padding: 4px 12px;
      border-radius: 8px;
      border: 1px solid #b00808;
      height: 24px;
      cursor: pointer;
      box-shadow: 0px 0px 0px 1px rgba(176,8,8,0);

      &:focus {
        border: 1px solid #b00808 !important;
        outline: none !important;
      }

      &:hover {
        border: 1px solid #b00808;
        box-shadow: 0px 0px 0px 1px rgba(176,8,8,0.76);
      }

      span {
        font-size: 11px;
        font-weight: 400;
        color: #b00808;
      }
    }
  }
}
