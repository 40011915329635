@import "../../../../assets/styles/variables.scss";

.new-maintenance-form-container {
    .mobile-layout & {
        position: absolute;
        bottom: 0;
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        box-shadow: 0px -2px 1px $deviders;
        .mobile-layout & {
            position: fixed;
        }
        left: 0;
        right: 0;
        bottom: 0;
        padding: 10px;
        background-color: white;
    }

    &__actions-main {
        & > *:not(:last-child) {
            margin-right: 5px;
        }
    }
}

.new-maintenance-form {
    background-color: white;
    font-size: 12px;
    width: 600px;
    padding: 20px;
    max-height: 85vh;
    overflow-y: scroll;
    //padding-bottom: 40px;

    > *:not(:last-child){
        margin-bottom: 20px;
    }

    &__header {
        font-weight: bold;
        font-size: 16px;
    }

    &__set {
        display: flex;

    }

    &__set-group {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    &__input {
        padding: 12px 0;
        background-color: $inactive2;
        flex-grow: 1;
        text-align: center;
        font-size: 14px;
        width: 100px;
    }

    &__text {
        font-size: 13px;
    }

    &__date {
        width: 50%;
    }

    &__error {
        color: #B00808;
        font-size: 14px;
        line-height: 10px;
    }
}

.mobile-layout
.new-maintenance-form {
    padding-bottom: 70px;
    width: 100%;
    &__actions {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 20px;
        background-color: white;
        box-shadow: 0px -2px 1px $deviders;
    }

    &__date {
        width: 100%;
    }
}