@import "../../../assets/styles/variables";
@import "./tagsMixins";

$dashboard-padding: 16px;
$sections-margin: 16px;
$search-height: 56px;
$table-header-height: 32px;
$checkbox-height: 16px;

.searchWrapper {
  margin: #{$sections-margin} 0 0;
  text-align: center;
  padding: 12px;
  background: $main_white;
  width: 100%;
  height: #{$search-height};

  .searchContainer {
    padding: 6px 12px;
    background: $bg_input;
    display: flex;
    column-gap: 8px;
    align-items: center;

    .searchIcon {
      width: 16px;
      height: 16px;
      color: $inactive;
      flex-shrink: 0;
    }

    .searchInput {
      border: none;
      background: none;
      padding: 0;
      font-size: 12px;
      line-height: 20px;
      flex: 1;
      color: $main_black;

      &::placeholder {
        color: $inactive;
      }
    }
  }
}

.tableHeader {
  @include tagsTableRow;
  height: #{$table-header-height};

  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  color: $main_black;
  background-color: $bg_input;
  border: 1px solid $secondary_gray;

  .headerItem {
    margin: 0;
  }

  .centerHeaderItem {
    text-align: center;
  }

  .toRightHeaderItem {
    text-align: right;
  }
}

.tagsList {
  $tags-list-height: calc(100%
  - #{$dashboard-padding}
  - #{$sections-margin}
  - #{$search-height}
  - #{$table-header-height}
  - #{$sections-margin}
  - #{$checkbox-height}
  - #{$dashboard-padding}
  );

  height: #{$tags-list-height};
}

.checkbox_24_hours {
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0;
  margin-top: #{$sections-margin};
  height: #{$checkbox-height};
  display: inline-flex;
  align-items: baseline;
  column-gap: 8px;
  cursor: pointer;
}