.show-details-button {
  &, &:focus {
    background: none;
    border: none;
    outline: none;
  }

  padding: 0;
  text-align: left;
  text-decoration-line: underline;
  cursor: pointer;
}