@import "../../../assets/styles/variables";

.unique-thing {
  padding: 5px 10px;
  a {
	font-size: 13px;
  }
}
.report-filter-begin {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .btn {
    padding: 8px 12px;
    background-color: $main_black;
    color: $main_white;
    display: flex;
    .icon {
      width: 24px;
    }
  }
}
.report-summary {
  font-size: 11px;
  line-height: 12px;

  padding: 20px;
  display: flex;
  justify-content: center;
  flex-basis: 200px;
	.mobile-layout & {
		flex-direction: column;
		position: relative;
		padding: 0;
	}

  &.dashboard-open {
    flex-direction: column;
    .report-summary-table {
      display: none;
    }
    .report-summary-actions {
      height: 28px;
      flex-basis: auto;
      grid-template-columns: 28px;
      grid-template-rows: 1fr;
      .filters-btn {
        grid-column: 1 / 2;
        grid-row: 1;
      }
      .export-btn {
        grid-column: 2 / 5;
        grid-row: 1;
      }
      .generate-report-btn {
        grid-column: 5 / 8;
        grid-row: 1;
      }
    }
  }
  &.report-summary--end {
    justify-content: flex-end;
  }

  .report-summary-table {
    flex-grow: 3;
    display: flex;
    justify-content: space-around;
	.mobile-layout & {
		flex-direction: column;
		padding: 16px 0 24px;
	}

    .report-summary-col {
      display: flex;
      flex-basis: 200px;
      flex-direction: column;
      justify-content: space-around;

	  .mobile-layout & {
	  	flex-basis: unset;

	  	&:first-child {
	  		margin-bottom: 12px;
	  	}
	  }

      .report-summary-data {
        max-width: 200px;
        display: flex;
        justify-content: space-between;
		.mobile-layout & {
			max-width: 100%;
			padding-bottom: 12px;

			&:last-of-type {
				padding-bottom: 0;
			}
		}

        .report-summary-data-value {
          font-weight: bold;
        }
      }
    }

    &.report-summary-table--one-col {
      justify-content: flex-end;

      .report-summary-col:last-child {
        margin-right: 20px;
      }
    }
  }
  .report-summary-actions {
    flex-basis: 153px;
    height: 60px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 28px);
    gap: 5px;

	.mobile-layout & {
		height: max-content;
		flex-basis: unset;
		border-top: 1px solid $dividers;
		margin-bottom: 32px;
		display: flex;
		flex-direction: row-reverse;
		align-items: center;
		width: 100vw;
		padding: 16px 20px 0;
		transform: translateX(-20px);
	}

    .generate-report-btn {
      display: flex;
      grid-column: 1 / 5;
		.mobile-layout & {
			width: 50%;
		}
      button {
        flex-grow: 1;
      }
    }
    .filters-btn {
      grid-column: 5 / 6;
    }
    .export-btn {
      grid-column: 1 / 6;
      grid-row: 2 / 2;
    }
  }
}
.report-page-section {
  padding: 20px;
}
.report-page-container {
  background-color: $main_white;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;

	.mobile-layout & {
		background: $main_gray;
	}
}
.mobile-layout .scrollbar-container .report-summary-actions {
	position: fixed;
	bottom: 110px;
	width: calc(100% - 40px);
	display: flex;
	justify-content: space-between;

	& .but {
		border-radius: 50%;
		padding: 14px;
		height: max-content;
	}
  margin-bottom: 50px;
}
.report-page-report-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  padding: 20px;

  font-size: 12px;
  line-height: 12px;
  border-bottom: 1px solid $dividers;

  .mobile-layout & {
  	padding: 16px 0;
  }

  .report-filters-actions {
    margin: 10px 0;
	.mobile-layout & {
		margin: 10px;
	}
  }
  .report-filters-actions span {
    color: $main_black;
    white-space: nowrap;
    font-size: 12px;
    line-height: 12px;
  }

  .filter-indicators {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    .dates {
      text-align: start;
    }

    .filter-indicator-item {
      color: black;
      margin-right: 5px;
      margin-bottom: 5px;
	  .mobile-layout & {
	  	background: transparent;
	  	padding: 10px;
	  	border-radius: 4px;
	  }

      .time {
        color: $inactive;
      }
    }
  }
}
.report-tables {
  display: flex;
  flex-direction: column;
	.mobile-layout & {
		h3 {
			font-size: 16px;
			line-height: 16px;
			margin: 0;
		}

		.tables-wraper {
			&>div:first-child {
				margin-bottom: 16px;
			}

			button:first-of-type {
				margin-right: 8px;
			}
		}
	}
}
.report-creteria-label {
  padding: 8px 12px;
  background-color: white;
  border-radius: 4px;
  color: $main_black;
  box-sizing: border-box;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  .select-filters-link {
    background-color: $main_black;
    padding: 8px 12px;
    color: $main_white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 2px;
    font-size: 14px;
    line-height: 14px;
    width: 140px;
    height: 36px;
    box-sizing: border-box;

    &:hover {
      color: $main_white;
      text-decoration: none;
    }

    img {
      width: 20px;
    }
  }
}
.report-filters-actions-container {
  display: flex;
  justify-content: space-around;
  padding: 20px;
  .filters-actions {
    display: flex;
    align-items: center;
    button {
      cursor: pointer;
      font-size: 12px;
      line-height: 12px;
      border: none;
      background-color: $main_white;
      text-decoration: underline;
      white-space: nowrap;
    }
  }
  .filters {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    & > * {
      margin-bottom: 10px;
    }

    .date-filter-label {
      padding: 8px 12px;
      white-space: normal;
      background-color: $main_white;
      border: 1px solid $secondary_gray;
      border-radius: 4px;
      color: $main_black;
    }
  }
}
.report-page-subnav-section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  font-size: 12px;
  .mobile-layout & {
  	padding: 16px 0;
  	justify-content: space-between;

  	&.but-md {
  		padding: 8px 12px;
  	}
  }

  & > * {
    margin: 5px 0;
  }
  .visible-btn {
    font-size: 12px;
  }

  .filter-btn-container {
    flex-grow: 1;
    text-align: end;
    .restrictive-container {
      text-align: start;
      margin-left: auto;
      width: fit-content;
      .visible-report-data-container {
        .visible-report-data-container {
          margin-right: 5px;
          .mobile-layout & {
            margin: 0;
            flex-grow: inherit;

            .but {
              padding: 0;
            }

            svg {
              margin-right: 4px;
            }
          }
        }
      }
    }
  }

  .toggle-container {
    margin: 0 5px;
  }


  .all-on-map-container {
    margin-left: 5px;
  }
}
.device-person-toggle {
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  margin: 0 5px;

  .mobile-layout & {
  	margin: 0 8px;
  }

  .visible-report-data-btn {
    flex-grow: 1;
  }
  .visible-report-btn {
    background-color: transparent;
    color: $inactive;
    .icon {
      fill: gray;
    }
  }

  .toggle {
    width: 24px;
    height:20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: $dividers;
    color: $inactive;
    &.active {
      background-color: $main_black;
      color: $main_white;
    }
  }
  .action-btn {
    font-size: 11px;
    line-height: 12px;

    margin-left: 10px;
    cursor: pointer;
    padding: 0 10px;
    background-color: $dividers;
    color: $inactive;
    &.active {
      background-color: $main_black;
      color: $main_white;
    }
    .icon {
      width: 8px;
      margin-right: 4px;
    }
  }
}
.report-device-section {
  padding: 20px;
  .mobile-layout & {
  	padding: 0;
  }
}
.report-device-action-btn {
  &:first-child {
    margin-right: 4px;
  }
  &:focus {
    outline: none;
  }
  border: none;
  outline: none;
  border-radius: 2px;
  &.active {
    background-color: $main_black;
    .icon path{
      fill: $main_white;
    }
  }
  .icon {
    width: 10px;
  }
}
.report-filter-indicators-container {
  background-color: $main_white;
  flex-grow: 1;
  border-bottom: 1px solid $main_gray;
}
.report-device-section {
  margin-bottom: 50px;
}
.report-device-section-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
  & h3 {
    margin-bottom: 0;;
  }

  .report-device-section-actions {
    button {
      border: none;
      outline: none;
      margin-left: 5px;
      padding: 0;
      .icon{
        background-color: $deviders;
        border-radius: 5px;
        display: flex;
        padding: 6px;
        svg {
          width: 18px;
          height: 18px;
          & path {
            fill: #DADADA;
          }
        }

        &.active {
          background-color: $main_black;
          svg path {
            fill: $main_white;
          }
        }
      }
    }

  }

  h3 {
    font-size: 16px;
    line-height: 16px;
  }
}


.mobile-layout {
  .report-page-report-filters {
    padding: 0 10px;
  }

  .filter-indicators {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
}
.travelPagination.bottom {
  padding-bottom: 100px;
}

.travelPagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;

  .paginateBtn {
    padding: 5px 10px;
    background: #FF7C02;
    color: white;
    border-radius: 5px;
    font-size: 14px;
    width: 100px;
    height: 37px;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.8;
    }
  }

  .paginateBtnDisabled {
    padding: 5px 10px;
    background: lightgrey;
    color: white;
    border-radius: 5px;
    font-size: 14px;
    width: 100px;
    height: 37px;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
}

.travel-report-pagination {
  display: flex;
  gap: 15px;
  margin: 0 auto 100px;

  button {
    width: 80px;
    padding: 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: white;
    border-radius: 12px;
    background-color: #111;
    transition: background-color 0.3s;
    cursor: pointer;

    &:hover {
      background-color: #ff7c02;
    }
  }
}

.travelPaginationBottom {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-bottom: 50px;

  .paginateBtn {
    padding: 5px 10px;
    background: #FF7C02;
    color: white;
    border-radius: 5px;
    font-size: 14px;
    width: 100px;
    height: 37px;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.8;
    }
  }

  .paginateBtnDisabled {
    padding: 5px 10px;
    background: lightgrey;
    color: white;
    border-radius: 5px;
    font-size: 14px;
    width: 100px;
    height: 37px;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
}

.staticPagination {
  display: flex;
  margin-bottom: 150px;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  div {
    display: flex;
    gap: 10px;
    align-items: center;

    span {
      padding: 3px 15px;
      color: #fff;
      background-color: #000;
      border-radius: 2px;
    }

    button {
      color: #fff;
      background-color: #ff7c02;
      padding: 5px;
      width: 130px;
      font-size: 14px;
      border-radius: 2px;

      &:disabled {
        background-color: #6a6a6a;
        cursor: not-allowed;
      }
    }
  }
}

.permission-report-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.permission-report-card {
  text-align: center;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  margin-bottom: 30px;
}

.permission-report-devices {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  padding: 20px 15px;
  border-radius: 10px;
}

.permission-report-tag {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  flex-direction: column;
  padding: 20px 15px;
  border-radius: 10px;
  margin-top: 15px;
}

.permission-report-tag-title {
  font-size: 1.2rem;
  color: #555;
  margin: 0 auto;
}

.permission-report-tag-devices {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.permission-report-title {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1rem;
}

.permission-report-subtitle {
  font-size: 2rem;
  color: #555;
  padding: 15px 5px 5px;
  border-top: 2px solid #e0e0e0;
  width: 80%;
  margin: 0 auto;
}

.permission-report-device-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background: #f7f7f7;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 150px;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.permission-report-device-name {
  font-size: 1.1rem;
  color: #222;
  margin-bottom: 0.25rem;
}

.permission-report-device-timezone {
  font-size: 0.9rem;
  color: #666;
}

.permissions-pagination {
  display: flex;
  margin: 0 auto;
  gap: 15px;
  margin-bottom: 50px;
  button {
    width: 80px;
    padding: 5px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: white;
    border-radius: 12px;
    background-color: #111;
    transition: background-color 0.3s;
    cursor: pointer;
    &:hover {
      background-color: #ff7c02;
    }
  }
}
