@import "../../../../assets/styles/variables";

.DeviceListItem {
  border-radius: 4px;
  background-color: $white;
  border: 1px solid transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  height: 32px;
  gap: 8px;

  &:hover {
    border-color: $deviders;

    .DeviceListItemFavorite {
      visibility: visible;
    }
  }

  .DeviceListItemInfoContainer {
    display: flex;
    gap: 12px;
    align-items: center;
    width: calc(100% - 24px);

    .DeviceListItemInfo {
      display: flex;
      gap: 8px;
      align-items: center;
      width: 100%;

      span {
        overflow: hidden;
        font-size: 12px;
        font-weight: 500;
        color: $main_black;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .DeviceListItemIndicator {
        width: 13px;
        height: 13px;
        border-radius: 50%;
        display: inline-block;
      }
    }
  }

  .DeviceListItemFavorite {
    width: 16px;
    height: 16px;
    visibility: hidden;

    &__favorite {
      visibility: visible;
    }
  }
}

.AssignedDriver {
  display: flex;
  align-items: center;
  gap: 2px;

  span {
    font-size: 11px !important;
    font-weight: 400 !important;
    letter-spacing: 0.11px !important;
    color: #878889 !important;
  }

  &__icon {
    flex-shrink: 0;
  }
}