.empty-card {
  min-width: 270px;
  height: 150px;

  .name {
    padding-top: 14px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    color: #ffffff;
  }

  .content {
    background-color: #ec6a1d;
    border-radius: 8px;
    height: 150px;
    overflow: hidden;
    position: relative;

    &:hover {
      cursor: pointer;

      .trash {
        opacity: 1;
      }
    }

    .wave {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  .center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 3;

    .trash {
      cursor: pointer;
      position: absolute;
      transition: all .3s ease-in;
      top: 12px;
      right: 16px;
      opacity: 1;
    }
  }

  &.black-card {
    .content {
      background-color: #3C3C3C;
    }
  }
}